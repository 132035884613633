
        var normalize = require("../../../node_modules/san-loader/lib/runtime/normalize.js");
        require('/root/ONLINE_SERVICE/other/ferry/task_workspace/baidu/smart-fe/finance-pc/src/components/self-stock-list/self-stock-list.san?lang=less&scoped=&san=&type=style&index=0');
var injectStyles = [];

        var template = require('/root/ONLINE_SERVICE/other/ferry/task_workspace/baidu/smart-fe/finance-pc/src/components/self-stock-list/self-stock-list.san?lang=html&san=&type=template');
        
        var script = require('/root/ONLINE_SERVICE/other/ferry/task_workspace/baidu/smart-fe/finance-pc/src/components/self-stock-list/self-stock-list.san?lang=js&san=&type=script').default;
        module.exports = require('/root/ONLINE_SERVICE/other/ferry/task_workspace/baidu/smart-fe/finance-pc/src/components/self-stock-list/self-stock-list.san?lang=js&san=&type=script');
    
        module.exports.default = normalize(script, template, injectStyles);
        /* san-hmr component */
    