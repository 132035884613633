// import {Store} from 'san-store';'
import {store} from '@/lib/Store';
import {builder} from 'san-update';
import {getSelfStockList, getSelfselectData, getMainAnalysis} from '@/api';
import {getLoginInfo} from '@/api';
import {ParisFactory} from '@baidu/paris';
import {removeSkeleton} from '@/utils/util';

const curStore = {
    initData: {
        // 登录相关信息
        loginInfo: {
            isLogin: false,
            avatar: '',
        },
        // 搜索框聚焦失焦控制
        searchInputFocus: false,
        // 是否显示search
        isShowSearch: true,
        // 是否是超级视图点击
        isSuperViewClick: false,
        // 底部footer显示影藏控制
        showFooter: false,
        // 管理自选股列表
        selfStockList: {
            all: [],
        },
        recStockList: [],
        // 是否更新自选列表页
        updateSelfList: '',
        curPage: '',
        curPageParams: {},
        sideNavLeft: -400,
        allSelfStockData: [],
        dataIsReady: false,
        pSid: '2108',
        quotationMarket: '',
        // 行情图 tab，用于超级视图和普通视图联动锚点
        curMarketChartTab: null,
        // 分析模块数据
        mainAnalysis: {
            isLoading: false,
            data: null,
            error: null,
        },
    },

    actions: {
        changeUserName(name) {
            return builder().set('user.name', name);
        },
        setDefaultMarket(market) {
            return builder().set('quotationMarket', market);
        },
        setCurPage(page) {
            return builder().set('curPage', page);
        },
        setCurPageParams(params) {
            return builder().set('curPageParams', params);
        },
        changeSearchInputFocus(status) {
            return builder().set('searchInputFocus', status);
        },
        changeShowClickSearch(status) {
            return builder().set('isShowSearch', status);
        },
        changeSuperViewClick(status) {
            return builder().set('isSuperViewClick', status);
        },
        setShowFooter(showFooter) {
            return builder().set('showFooter', showFooter);
        },
        setLoginInfo(loginInfo) {
            return builder().set('loginInfo', loginInfo);
        },
        // 添加自选股列表到 store 内
        setSelfStockList({market, list}, {getState}) {
            const old = getState('selfStockList');
            old[market] = list;
            return builder().set('selfStockList', {...old});
        },
        // 添加推荐股票
        setRecList(list) {
            return builder().set('recStockList', list);
        },
        // 当添加或者删除自选时，更新自选列表页数据
        setUpdateSelfList(val) {
            return builder().set('updateSelfList', val);
        },
        // 获取新的自选股列表
        getSelfStockLists({market = '', type = ''}, {getState, dispatch}) {
            getLoginInfo().then(res => {
                const {isLogin, avatar = ''} = res || {};
                dispatch('setLoginInfo', {
                    isLogin: +isLogin === 1,
                    avatar,
                });
                getSelfStockList({
                    market,
                    type: type,
                    skipLogin: +isLogin === 1 ? 0 : 1,
                }).then(res => {
                    const {recommend_stock, stock} = res;
                    dispatch('setSelfStockList', {
                        market: type !== 'futures' ? (market ? market : 'all') : 'fut',
                        list: stock,
                    });
                    const recommend = res[type ? 'recommend_' + type : 'recommend_stock'];
                    dispatch('setRecList', recommend);
                    removeSkeleton();
                });
            });
        },
        setDataIsReady() {
            return builder().set('dataIsReady', true);
        },
        setAllSelfStockData(list) {
            return builder().set('allSelfStockData', list);
        },
        // 获取最新的全部自选数据
        getAllSelfStockData({market = '', type = ''}, {getState, dispatch}) {
            getLoginInfo().then(res => {
                const {isLogin, avatar = ''} = res || {};
                dispatch('setLoginInfo', {
                    isLogin: +isLogin === 1,
                    avatar,
                });
                getSelfselectData({
                    market,
                    type,
                    skipLogin: +isLogin === 1 ? 0 : 1,
                }).then(res => {
                    dispatch('setDataIsReady');
                    if (res?.Result?.stock?.length) {
                        dispatch('setAllSelfStockData', res.Result.stock);
                    }
                });
            });
        },
        // 打开关闭左侧导航栏
        changeSideNav(flag) {
            const left = flag ? 0 : -400;
            return builder().set('sideNavLeft', left);
        },
        /**
         * 玉门关反爬接口，应尽早初始化
         * @param sid 股市通特有
         */
        getParis(p, {getState, dispatch}) {
            const sid = getState('pSid');
            // 加载后请立即初始化配置
            ParisFactory.create({
                sid: sid,
                timeout: 5000, // CDN的请求超时时间
                abcliteUrl: `https://dlswbr.baidu.com/heicha/mw/abclite-${sid}-s.js`,
                acsUrl: `https://dlswbr.baidu.com/heicha/mm/${sid}/acs-${sid}.js`,
                // 容灾配置
                disasterConfig: {
                    // 备用CDN地址
                    acsUrl: `https://miaowu.baidu.com/sdk/heicha/mm/${sid}/acs-${sid}.js`,
                    // 备用CDN地址
                    abcliteUrl: `https://miaowu.baidu.com/sdk/heicha/mw/abclite-${sid}-s.js`,
                    // 主服务器上报失败后的备用上报地址
                    secondReportUrl: 'https://miaowu.baidu.com/slim/abdr',
                },
            });
        },
        // 设置行情图当前的tab项
        setMarketChartTab(tab) {
            return builder().set('curMarketChartTab', tab);
        },
        // 设置分析模块的数据
        serMainAnalysis(info) {
            return builder().set('mainAnalysis', info);
        },
        // 获取分析模块的数据（多处使用，所以使用store）
        getMainAnalysis(params = {}, {getState, dispatch}) {
            dispatch('serMainAnalysis', {
                isLoading: true,
                data: null,
                error: null,
            });
            getMainAnalysis(params).then(res => {
                dispatch('serMainAnalysis', {
                    isLoading: false,
                    data: res,
                    error: null,
                });
            }).catch(() => {
                dispatch('serMainAnalysis', {
                    isLoading: false,
                    data: null,
                    error: true,
                });
            });
        },
    },
};

store.initData(curStore.initData).addActions(curStore.actions);

export default store;
