<template>
    <!--热搜股票-->
    <div s-ref="hot-search-wrapper" class="c-bg-color-white c-border-raidus-middle {{inSideNav ? 'in-side-nav-wrapper' : 'hot-search-wrapper'}}" data-s-4d3557f0>
        <div class="hot-header c-color" data-s-4d3557f0>
            <div class="c-font-title01 hot-title" on-click="toList" data-s-4d3557f0>百度热股榜<i class="arrow s-icon" data-s-4d3557f0></i></div>
            <div class="drop-down-wrapper" s-if="{{filterData}}" data-s-4d3557f0>
                <drop-down class="drop-down1" defaultValue="{{filterData.marketText}}" selectOptionArr="{{filterData.marketOptions}}" on-handle-menu-click="onMarketChange" data-s-4d3557f0></drop-down>
                <drop-down class="drop-down2" defaultValue="{{filterData.typeText}}" selectOptionArr="{{filterData.typeOptions}}" on-handle-menu-click="onTypeChange" data-s-4d3557f0></drop-down>
            </div>
        </div>
        <div data-s-4d3557f0>
            <capsule-tabs title-list="{{tabList}}" arr-type="kvArr" active-index="{{activeTabIndex}}" on-event-click="tabChange" data-s-4d3557f0></capsule-tabs>
        </div>

        <div class="header-title-wrapper" data-s-4d3557f0>
            <div data-s-4d3557f0>代码/名称</div>
            <div class="small-header-title" data-s-4d3557f0>
                <span style="margin-right: {{inSideNav ? 0 : newPriceRight}}rem;" class="right-label right-label-price" data-s-4d3557f0>涨跌幅</span>
                <span class="right-label right-label-ratio" data-s-4d3557f0>热度</span>
            </div>
        </div>
        <div class="hot-stock-item" on-click="onHotStockItem(item)" s-for="item,index in list" data-s-4d3557f0>
            <div class="hot-stock-item-name" data-s-4d3557f0>
                <div class="index-wrapper {{item.rank && item.isUp === '1' && item.rank > 1 && 'small-index-wrapper'}}" data-s-4d3557f0>
                    <div class="num num-{{index + 1}}" data-s-4d3557f0>{{index + 1}}</div>
                    <div class="num-svg {{item.rank && item.isUp != '1' && 'num-down'}}" s-if="item.rank" data-s-4d3557f0>
                        <div class="stock-num {{item.isUp === '1' ? 'stock-up' : 'stock-down'}}" data-s-4d3557f0></div>
                        <span class="c-font-tiny rank-num {{item.isUp === '1' ? 'rank-num-up' : 'rank-num-down'}}" data-s-4d3557f0>{{item.rank}}</span>
                    </div>
                </div>
                <div class="marking" data-s-4d3557f0>
                    <stock-marking name="{{item.name}}" code="{{item.code}}" type="{{item.exchange}}" belongingBlock="{{item.belongingBlock}}" data-s-4d3557f0></stock-marking>
                </div>
            </div>
            <div class="hot-right" data-s-4d3557f0>
                <div class="right-label color-{{item.colorStatus}} middle-box" style="margin-right: {{inSideNav ? 0 : newPriceRight}}rem;" data-s-4d3557f0>{{item.ratio}}</div>
                <div class="right-label heat-box" data-s-4d3557f0>
                    <span class="heat-icon" data-s-4d3557f0></span>
                    {{item.heat}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDown from '@/components/dropdown/dropdown';
import StockMarking from '@/components/stock-marking/stock-marking';
import CapsuleTabs from '@/components/capsule-tabs/capsule-tabs';
import {getHotStock, getHotSreach, getBlock, getPublicTabList} from '@/api/index';
import {addZero} from '@/utils/util';
import {Component} from 'san';
import computedHot from '@/components/all-rank/rank';

import {
    tabList,
    marketOptions,
    timeOptions,
    financeTypeOptions,
    marketMap,
} from './config';

let timer = null;
export default class HotSearchStock extends Component {
    static components = {
        'drop-down': DropDown,
        'stock-marking': StockMarking,
        'capsule-tabs': CapsuleTabs,
    };

    initData() {
        return {
            // tab 标识（热股 stock、热搜 search、板块 plate、舆情 publicOpinion）
            product: 'stock',
            inSideNav: false,
            activeTabIndex: 1,
            tabList,
            list: [],
            newPriceRight: 0.3,
        };
    }

    static computed = {
        filterData() {
            const product = this.data.get('product');
            switch (product) {
                case 'stock':
                case 'search':
                    return {
                        marketText: '全部',
                        typeText: '1小时',
                        marketValue: 'all',
                        typeValue: 'hour',
                        marketOptions,
                        typeOptions: timeOptions,
                    };
                case 'plate':
                    return null;
                case 'publicOpinion':
                    return {
                        marketText: '全部',
                        typeText: '全部',
                        marketValue: '',
                        typeValue: '',
                        marketOptions,
                        typeOptions: financeTypeOptions,
                    };
                default:
                    return null;
            }
        },

        activeTabIndex() {
            const tabList = this.data.get('tabList');
            const product = this.data.get('product');
            const index = tabList.findIndex(i => i.type === product);
            return index > 0 ? index : 0;
        },
    }

    created() {
        this.requestDistribution();
    }

    tabChange(e) {
        this.data.set('product', e?.text?.type);
        this.requestDistribution();
    }

    requestDistribution() {
        const product = this.data.get('product');
        switch (product) {
            case 'stock':
                this.getHotStock();
                break;
            case 'search':
                this.getHotSearchStock();
                break;
            case 'plate':
                this.getHotPlate();
                break;
            case 'publicOpinion':
                this.getPublicOpinion();
                break;
            default:
                break;
        }
    }

    // 获取年月日或小时
    getDateOrHour() {
        const date = new Date();
        const year = date.getFullYear();
        const mounth = addZero(date.getMonth() + 1);
        const mounthDay = addZero(date.getDate());
        const hour = date.getHours();
        return {
            day: `${year}${mounth}${mounthDay}`,
            hour,
        };
    }

    // 热股数据
    getHotStock() {
        const {day, hour} = this.getDateOrHour();
        const {marketValue, typeValue} = this.data.get('filterData');
        const params = {
            day,
            hour,
            pn: 0,
            rn: 10,
            market: marketValue,
            type: typeValue,
        };
        getHotStock(params).then(res => {
            if (res?.Result?.body?.length) {
                const {Result} = res;
                const stocks = [];
                for (let k of Result.body) {
                    stocks.push({
                        name: k[0],
                        code: k[3],
                        exchange: k[5].toLowerCase(),
                        rank: this.getRank(k[6]),
                        isUp: +k[6] > 0 ? '1' : '0',
                        heat: computedHot(k[8]),
                        colorStatus: this.byRatioGetStatus(k[1]),
                        market: k[7],
                        ratio: k[1],
                        belongingBlock: k[2],
                    });
                }
                this.data.set('list', stocks);
            }
        });
    }

    // 热搜数据
    getHotSearchStock() {
        const {day, hour} = this.getDateOrHour();
        const {marketValue, typeValue} = this.data.get('filterData');
        const params = {
            day,
            hour,
            pn: 0,
            rn: 10,
            market: marketValue,
            type: typeValue,
        };
        getHotSreach(params).then(res => {
            if (res?.Result?.body?.length) {
                const {Result} = res;
                const searchStocks = [];
                for (let k of Result.body) {
                    searchStocks.push({
                        name: k[0],
                        code: k[4].split('.')[0],
                        exchange: k[4].split('.')[1],
                        rank: this.getRank(k[6]),
                        isUp: +k[6] > 0 ? '1' : '0',
                        heat: computedHot(k[2]),
                        colorStatus: this.byRatioGetStatus(k[1]),
                        market: marketMap[k[4].split('.')[1].toUpperCase()] || k[4].split('.')[1].toLowerCase(),
                        ratio: k[1],
                        belongingBlock: k[3],
                    });
                }
                this.data.set('list', searchStocks);
            }
        });
    }

    // 板块数据
    getHotPlate() {
        const {day, hour} = this.getDateOrHour();
        const params = {
            day,
            hour,
            pn: 0,
            rn: 10,
        };
        getBlock(params).then(res => {
            if (res?.Result?.body?.length) {
                const {Result} = res;
                const plates = [];
                for (let k of Result.body) {
                    plates.push({
                        name: k[0],
                        code: k[3],
                        market: marketMap[k[5].split('.')[1].toUpperCase()] || k[5].split('.')[1].toLowerCase(),
                        heat: computedHot(k[2]),
                        colorStatus: this.byRatioGetStatus(k[1]),
                        ratio: k[1],
                        belongingBlock: k[4],
                    });
                }
                this.data.set('list', plates);
            }
        });
    }

    // 舆情数据
    getPublicOpinion() {
        const {marketValue, typeValue} = this.data.get('filterData');
        const params = {
            pn: 0,
            rn: 10,
            market: marketValue,
            financeType: typeValue === 'block' ? 'plate' : typeValue,
        };
        getPublicTabList(params).then(res => {
            const data = res?.Result?.[0].TplData?.aiSentimentRankInfo?.body;
            if (data?.length) {
                const publicOpinions = data.map(item => {
                    return {
                        name: item.name,
                        exchange: item.exchange,
                        heat: computedHot(item.heat),
                        code: item.code,
                        ratio: item.ratio,
                        colorStatus: this.byRatioGetStatus(item.ratio),
                        rank: this.getRank(item.rankDiff),
                        isUp: +item.rankDiff > 0 ? '1' : '0',
                        belongingBlock: item.plate,
                        type: item.type,
                        market: item.market,
                    };
                });
                this.data.set('list', publicOpinions);
            }
        });
    }

    getRank(val) {
        const newVal = Math.abs(+val);
        if (newVal === 0) {
            return '';
        }
        return newVal > 99 ? '99+' : newVal;
    }

    byRatioGetStatus = ratio => {
        const ratioNum = parseFloat(ratio);
        if (isNaN(ratioNum)) {
            return 'same';
        }
        if (ratioNum > 0) {
            return 'up';
        }
        else if (ratioNum < 0) {
            return 'down';
        }
        return 'same';
    }

    attached() {
        this.initNewPriceRight();
        this.watchOnResize();
    }

    // 响应式处理
    initNewPriceRight() {
        const hotSearchWrapperW
            = this.ref('hot-search-wrapper') && this.ref('hot-search-wrapper').offsetWidth;
        const fiestWidth = 370;
        const secondWidth = 350;
        // 随着屏幕的缩放，进而控制每一列的margin-right
        const rightRem = [0, 0.1, 0.3];
        if (hotSearchWrapperW < fiestWidth && hotSearchWrapperW > secondWidth) {
            this.data.set('newPriceRight', rightRem[1]);
        } else if (hotSearchWrapperW <= secondWidth) {
            this.data.set('newPriceRight', rightRem[0]);
        } else {
            this.data.set('newPriceRight', rightRem[2]);
        }
    }

    watchOnResize(newPriceRight) {
        window.onresize = () => {
            this.initNewPriceRight.bind(this)();
        };
    }

    onMarketChange(e) {
        const filterData = this.data.get('filterData');
        const cur = filterData.marketOptions.filter(i => i.value === e.key)[0];
        this.data.set('filterData.marketValue', cur.value);
        this.data.set('filterData.marketText', cur.label);
        this.requestDistribution();
    }

    onTypeChange(e) {
        const filterData = this.data.get('filterData');
        const cur = filterData.typeOptions.filter(i => i.value === e.key)[0];
        this.data.set('filterData.typeValue', cur.value);
        this.data.set('filterData.typeText', cur.label);
        this.requestDistribution();
    }

    onHotStockItem(item) {
        const {market, code, type} = item;
        const product = this.data.get('product');
        switch (product) {
            case 'stock':
            case 'search':
                this.$router.push({
                    path: `/stock/${market}-${code}`,
                });
                break;
            case 'plate':
                this.$router.push({
                    path: `/block/${market}-${code}`,
                });
                break;
            case 'publicOpinion':
                this.$router.push({
                    path: `/${type}/${market}-${code}`,
                });
                break;
            default:
                break;
        }
    }

    toList() {
        const {marketValue, typeValue} = this.data.get('filterData') || {};
        const product = this.data.get('product');
        let query = {};
        switch (product) {
            case 'stock':
                query = {
                    activeTab: 0,
                    market: marketValue,
                    type: typeValue,
                };
                break;
            case 'search':
                query = {
                    activeTab: 1,
                    market: marketValue,
                    type: typeValue,
                };
                break;
            case 'plate':
                query = {
                    activeTab: 2,
                };
                break;
            case 'publicOpinion':
                query = {
                    activeTab: 3,
                    market: marketValue,
                    financeType: typeValue,
                };
                break;
            default:
                break;
        }
        this.$router.push({
            path: '/hotlist',
            query,
        });
    }
}
</script>

<style lang="less" scoped>

.hot-search-wrapper[data-s-4d3557f0],
.in-side-nav-wrapper[data-s-4d3557f0] {
    position: relative;
    padding: .08rem .24rem 0 .24rem;

    .hot-title {
        z-index: 11;
        cursor: pointer;
    }

    .hot-header {
        display: flex;
        justify-content: space-between;
        padding: .13rem 0;
        height: .5rem;
        box-sizing: border-box;

        .c-font-title01 {
            font-weight: 500;
        }

        .drop-down-wrapper {
            display: flex;
        }

        .drop-down1 {
            margin-right: .16rem;
        }

        .drop-down2 {
            z-index: 9;
        }
    }

    .header-title-wrapper {
        display: flex;
        justify-content: space-between;
        padding: .1rem 0;
        color: #525352;
        font-size: .13rem;

        .small-header-title {
            display: flex;
            justify-content: space-between;

            .right-label-price {
                width: .7rem;
            }

            .right-label-ratio {
                width: .6rem;
            }
        }
    }

    .hot-stock-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: .64rem;
        box-shadow: inset 0 .01rem 0 0 rgba(224, 224, 224, .3);
        cursor: pointer;

        &:hover {
            background-color: #f8f9ff;
        }

        &:hover.hot-stock-item::before {
            content: '';
            background-color: #f8f9ff;
        }

        &:hover.hot-stock-item::after {
            content: '';
            background-color: #f8f9ff;
        }

        .hot-right {
            white-space: nowrap;
            font-size: 14px;
            color: #1f1f1f;
            display: flex;
            align-items: center;

            .right-label {
                flex-shrink: 0;
                width: .6rem;
            }

            .middle-box {
                flex-shrink: 0;
                width: .7rem;
            }
        }

        .hot-stock-item-name {
            display: flex;

            .index-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-shrink: 0;
                margin-right: .1rem;

                .num-svg {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;
                }

                .num {
                    font-size: 14px;
                    line-height: 14px;
                    width: 20px;
                    color: #858685;
                    font-weight: 600;
                }

                .num-1 {
                    font-size: 14px;
                    color: #ff003d;
                    line-height: 14px;
                    font-weight: 600;
                }

                .num-2 {
                    font-size: 14px;
                    color: #ff5a00;
                    line-height: 14px;
                    font-weight: 600;
                }

                .num-3 {
                    font-size: 14px;
                    color: #ffa600;
                    line-height: 14px;
                    font-weight: 600;
                }

                .stock-num {
                    width: .08rem;
                    height: .08rem;
                    background-size: 100% 100%;

                    i {
                        font-size: .08rem;
                    }
                }

                .stock-up {
                    background-image: url(https://gips2.baidu.com/it/u=3835752096,3085861391&fm=3028&app=3028&f=PNG&fmt=auto&q=75&size=f21_18);
                }

                .stock-down {
                    background-image: url(https://gips3.baidu.com/it/u=1546620724,1123657337&fm=3028&app=3028&f=PNG&fmt=auto&q=75&size=f21_21);
                }

                .rank-num {
                    position: relative;
                    margin-left: .02rem;
                }
            }

            .small-index-wrapper {
                margin-right: .03rem;
            }

            .marking {
                width: 1rem;
            }
        }
    }

    .hot-stock-item::before {
        content: '';
        position: absolute;
        left: -.24rem;
        top: 0;
        width: .24rem;
        height: 100%;
        background-color: transparent;
    }

    .hot-stock-item::after {
        content: '';
        position: absolute;
        right: -.24rem;
        top: 0;
        width: .24rem;
        height: 100%;
        background-color: transparent;
    }
}

.hot-search-wrapper[data-s-4d3557f0] {
    min-width: 3.36rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: .64rem;
        background-image: linear-gradient(180deg, rgba(241, 64, 65, .08) 0%, rgba(250, 83, 27, 0) 100%);
        border-radius: .1rem;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: .9rem;
        height: .64rem;
        background: url('/src/assets/images/gradient-arrow.png') no-repeat;
    }
}

.in-side-nav-wrapper[data-s-4d3557f0] {
    min-width: 3rem;
    position: none;
}

.right-label[data-s-4d3557f0] {
    display: inline-block;
    text-align: right;
    white-space: nowrap;
}

.color-up[data-s-4d3557f0] {
    color: #f30;
}

.color-down[data-s-4d3557f0] {
    color: #00b05a;
}

.color-same[data-s-4d3557f0] {
    color: grey;
}

.heat-box[data-s-4d3557f0] {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .heat-icon {
        background-image: url('https://b.bdstatic.com/searchbox/icms/searchbox/img/rank_hot_0906.png');
        background-size: 100% 100%;
        display: inline-block;
        width: 12px;
        height: 12px;
    }
}

</style>
