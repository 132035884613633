<template>
    <div class="user-container" data-s-49cf93b2>
        <div s-if="loginInfo.isLogin" class="avatars" style="background-image: url({{loginInfo.avatar}});" data-s-49cf93b2>
            <div class="user-menu-wrap c-gap-inner-top-small" data-s-49cf93b2>
                <div class="list" data-s-49cf93b2>
                    <div class="login-out-btn menu-item" on-click="loginOut" data-s-49cf93b2>退出登录</div>
                </div>
            </div>
        </div>
        <s-button s-else class="login-btn" text="登录" type="primary" min-width="78" on-click="login" data-s-49cf93b2></s-button>
        <s-modal show="{{showModal}}" title desc on-confirm="confirm" on-cancel="cancel" data-s-49cf93b2></s-modal>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 登录 & 用户信息
 */

import {Component} from 'san';
import {loginOut} from '@/api';
import {connect} from '@/lib/Store';
import Button from '@/components/button/button';
import Modal from '@/components/modal/modal';

class User extends Component {
    static components = {
        's-button': Button,
        's-modal': Modal,
    }

    initData() {
        return {
            showModal: false,
        };
    }

    confirm() {
        this.data.set('showModal', false);
        loginOut();
    }

    cancel() {
        this.data.set('showModal', false);
    }

    login() {
        this.$login();
    }

    loginOut() {
        loginOut();
        // this.data.set('showModal', true);
    }
}

export default connect.san({
    loginInfo: 'loginInfo',
})(User);
</script>

<style lang="less" scoped>
.user-container[data-s-49cf93b2] {
    margin-left: .24rem;

    .avatars {
        position: relative;
        width: .39rem;
        height: .39rem;
        border-radius: 50%;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .user-menu-wrap {
            display: none;
            position: absolute;
            top: .39rem;
            right: 0;
            width: 104px;
            cursor: pointer;

            &::after {
                position: absolute;
                content: "";
                top: .03rem;
                right: .13rem;
                width: .11rem;
                height: .11rem;
                background-color: #fff;
                border-top-left-radius: .04rem;
                transform: rotateZ(45deg);
            }

            .list {
                padding: .04rem 0;
                background-color: #fff;
                border-radius: .1rem;
                overflow: hidden;
                box-shadow:
                    0 2px 4px -1px rgba(0, 0, 0, .04),
                    0 1px 10px 0 rgba(0, 0, 0, .05),
                    0 4px 5px 0 rgba(0, 0, 0, .06);

                .login-out-btn {
                    height: .4rem;
                    line-height: .4rem;
                    text-align: center;
                }

                .menu-item:hover {
                    background: rgba(65, 109, 249, .04);
                }
            }
        }

        &:hover > .user-menu-wrap {
            display: block;
        }
    }

    .login-btn {
        width: .78rem;
        height: .4rem;
    }
}
</style>
