/**
 * @file login.js
 * @author v_zhaoxinggang
 * @desc 金融 pc 站登录
 */

const loginInit = () => {
    // eslint-disable-next-line no-undef
    const instance = passport.pop.init({
        loginVersion: 'v4',
        apiOpt: {
            u: location.href,
            product: 'financesearch0x0c47',
            memberPass: true,
            safeFlag: 0,
            staticPage: `${location.protocol}//${location.hostname}:${location.port}/v3Jump.html`,
        },
        cache: false,
        authsite: [],
        registerLink: 'https://passport.baidu.com/v2/?reg&tpl=&u=',
        qrcodeCfg: {
            appName: '百度app',
            appHref: 'https://www.baidu.com',
        },
        onLoginSuccess() {
            // 登录成功后，执行回调函数，在跳转到u之前触发
            // 当前页面url不变，需要手动触发刷新
            location.reload();
        },
    });
    return instance;
};

export default loginInit();