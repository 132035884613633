<template>
    <div class="download-app-box" data-s-a08c5d5c>
        <div class="btn" data-s-a08c5d5c>
            下载APP
            <div class="btn-bg" data-s-a08c5d5c></div>
        </div>
        <div class="qr-code-box" style="width: {{showGushitong && showXiaogu ? '2.72rem' : '1.44rem'}};" data-s-a08c5d5c>
            <div s-if="showGushitong" class="item" data-s-a08c5d5c>
                <div data-s-a08c5d5c>股市通下载</div>
            </div>
            <div s-if="showXiaogu" class="item" data-s-a08c5d5c>
                <div data-s-a08c5d5c>小顾基金下载</div>
                <img src="https://gips2.baidu.com/it/u=1334701100,2094752515&fm=3028&app=3028&f=PNG&fmt=auto&q=100&size=f344_344" alt data-s-a08c5d5c>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @author anbang02
 * @desc 下载按钮，hover显示二维码
 */

import {Component} from 'san';

export default class DownloadApp extends Component {

    initData() {
        return {
            showXiaogu: false,
            showGushitong: false,
        };
    }

}
</script>

<style lang="less" scoped>
.download-app-box[data-s-a08c5d5c] {
    margin-left: .24rem;
    position: relative;

    .btn {
        border: 1px solid rgba(65, 109, 249, 1);
        border-radius: .1rem;
        font-size: .14rem;
        color: #416df9;
        width: .78rem;
        height: .4rem;
        line-height: .4rem;
        text-align: center;
        position: relative;

        .btn-bg {
            display: none;
            position: absolute;
            top: -1px;
            left: -1px;
            bottom: -1px;
            right: -1px;
            background: #fff;
            opacity: .5;
        }
    }

    .btn:hover {
        .btn-bg {
            display: block;
        }
    }

    .qr-code-box {
        position: absolute;
        top: .48rem;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .04), 0 1px 10px 0 rgba(0, 0, 0, .05), 0 4px 5px 0 rgba(0, 0, 0, .06);
        background: #fff;
        height: 1.7rem;
        right: 0;
        justify-items: center;
        align-items: center;
        padding: .12rem .08rem .08rem;
        border-radius: .1rem;
        display: none;

        &::after {
            position: absolute;
            content: "";
            top: -.05rem;
            right: .13rem;
            width: .11rem;
            height: .11rem;
            background-color: #fff;
            border-top-left-radius: .04rem;
            transform: rotateZ(45deg);
        }

        .item {
            display: flex;
            justify-items: center;
            align-items: center;
            font-size: .14rem;
            color: #1f1f1f;
            flex-direction: column;
            margin: .08rem;

            img {
                width: 1.12rem;
                height: 1.12rem;
                margin-top: .04rem;
            }
        }
    }
}

.download-app-box:hover[data-s-a08c5d5c] {
    .qr-code-box {
        display: flex;
    }
}
</style>
