<template>
    <div class="self-stock-container" data-s-645d4680>
        <div s-if="{{isNav}}" class="title-in-nav c-color c-font-title01" on-click="goSelf" data-s-645d4680>自选列表</div>
        <s-title s-else title="自选列表" on-click="titleClick" data-s-645d4680></s-title>
        <div class="s-capsule-tabs-scroll c-gap-bottom-middle" s-if="{{!isNav}}" data-s-645d4680>
            <s-capsule-tabs s-ref="selfCapsuleTabs" titleList="{{tags}}" on-event-click="tagChange" class="tag-box" data-s-645d4680></s-capsule-tabs>
            <div class="right-mask" data-s-645d4680></div>
        </div>
        <s-home-recommend-stock-list s-if="showList.length === 1 && showSelfRecommentStock && !isLoading && (tagIndex === 0 || tagIndex === 6)" list="{{recStockList}}" curMarket="{{curMarket}}" curType="{{curType}}" width="{{width}}" data-s-645d4680></s-home-recommend-stock-list>
        <div class="list" s-if="showList.length > 1 && !isLoading" data-s-645d4680>
            <div s-for="row in showList" class="row {{isNav ? 'c-gap-inner-left-large c-gap-inner-right-large' : ''}}" on-click="clickItem(row)" style="margin: {{isNav ? 0 : '0 -0.24'}}px;" data-s-645d4680>
                <div class="row-inner" data-s-645d4680>
                    <div class="{{isNav ? 's-row-left' : 'row-left'}}" data-s-645d4680>
                        <div class="item-header col" data-s-645d4680>{{row.left.text}}</div>
                        <div s-if="row.left.style === 'first'" data-s-645d4680>
                            <div class="name c-color s-line-clamp1" data-s-645d4680>{{row.left.name}}</div>
                            <div class="market-code" data-s-645d4680>
                                <s-market-label s-if="{{row.left.exchange}}" market="{{row.left.exchange}}" data-s-645d4680></s-market-label>
                                <div class="code c-color-gray {{row.left.exchange ? 'c-gap-left-tiny' : ''}}" data-s-645d4680>
                                    {{row.left.code}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-right" data-s-645d4680>
                        <template s-for="item in row.right" data-s-645d4680>
                            <div s-if="item && item.style === 'header'" class="item-header row-item" style="margin-left: {{isNav ? '0' : width <= minWidth ? '0.14' : '0.32'}}rem;" data-s-645d4680>{{item.text}}</div>
                            <div s-if="item && item.style === 'default'" class="default row-item" style="margin-left: {{isNav ? '0' : width <= minWidth ? '0.14' : '0.32'}}rem;" data-s-645d4680>
                                <div class="c-color c-font-normal text {{getColor(item.status)}}" data-s-645d4680>{{item.text}}</div>
                                <div s-if="{{item.subText}}" class="c-color-source text c-gap-top-small" data-s-645d4680>{{item.subText}}</div>
                            </div>
                            <div s-if="item && item.style === 'btn'" class="row-item" style="margin-left: {{isNav ? '0' : width <= minWidth ? '0.14' : '0.32'}}rem;" data-s-645d4680>
                                <div class="
                                        btn
                                        c-gap-inner-left-small
                                        c-font-normal
                                        {{getColor(item.status)}}
                                        {{getBgColor(item.status)}}
                                    " data-s-645d4680>
                                    {{item.text}}
                                    <i s-if="item.status === '1'" class="s-icon icon stock-color-red" data-s-645d4680></i>
                                    <i s-if="item.status === '-1'" class="s-icon icon stock-color-green" data-s-645d4680></i>
                                </div>
                                <div s-if="item.subText && item.other" class="btn-bottom" data-s-645d4680>
                                    <span class="c-color-source" data-s-645d4680>{{item.subText}}</span>
                                    <span class="
                                        c-color-source
                                        c-gap-left-tiny
                                        pan
                                        c-bg-color-light-gray
                                    " data-s-645d4680>{{item.other}}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div s-if="showList.length <= 6 && showList.length > 1 && !isLoading" class="bottom-control" data-s-645d4680>
            <div class="inner" on-click="searchFocus" data-s-645d4680>
                <i class="s-icon icon c-color" data-s-645d4680></i>
                <span class="text c-gap-left-small c-color" data-s-645d4680>添加更多自选股</span>
            </div>
        </div>
        <div class="loading" s-if="isLoading" data-s-645d4680>
            <s-spin data-s-645d4680></s-spin>
        </div>
        <div s-if="showList.length === 1 && !isLoading && tagIndex !== 0 && tagIndex !== 6" class="self-stock-error-page" data-s-645d4680>
            <i class="s-icon icon" data-s-645d4680></i>
            <div class="c-color c-font-normal c-gap-bottom-small" data-s-645d4680>暂无{{tags[tagIndex]}}</div>
            <div class="c-font-small c-color-gray c-gap-bottom" data-s-645d4680>点击下方按钮添加自选</div>
            <div class="button-box" data-s-645d4680>
                <s-button text="添加{{tags[tagIndex]}}" type="primary" min-width="129" on-click="toSearch" data-s-645d4680></s-button>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 自选股票列表
 */

import {Component, DataTypes} from 'san';
import {getSelfStockList} from '@/api';
import {connect} from '@/lib/Store';
import _ from 'lodash';
import {Spin} from 'santd';
import Title from '@/components/title/title';
import Button from '@/components/button/button';
import MarketLabel from '@/components/market-label/market-label';
import CapsuleTabs from '@/components/capsule-tabs/capsule-tabs';
import MatchModuleContainer from '@/components/match-module-container/match-module-container';
import HomeRecommendStockList from '@/components/home-recommend-stock-list/home-recommend-stock-list';

class SelfStockList extends Component {
    static dataTypes = {
        width: DataTypes.number,
        initList: DataTypes.array,
    }

    static components = {
        's-spin': Spin,
        's-title': Title,
        's-button': Button,
        's-market-label': MarketLabel,
        's-capsule-tabs': CapsuleTabs,
        's-match-module-container': MatchModuleContainer,
        's-home-recommend-stock-list': HomeRecommendStockList,
    }

    initData() {
        return {
            tagIndex: 0,
            middleWidth: 414,
            minWidth: 309,
            // 切换tag 获取自选列表所需参数，和tags对应
            params: [
                {market: '', type: ''},
                {market: 'ab', type: 'stock'},
                {market: 'hk', type: 'stock'},
                {market: 'us', type: 'stock'},
                {market: 'ab', type: 'fund'},
                {market: 'global', type: 'foreign'},
                {market: '', type: 'futures'},
            ],
            tags: ['全部', 'A股', '港股', '美股', '基金', '外汇', '期货'],
            curMarket: 'all',
            curType: '',
            list: [],
            isLoading: false,
        };
    }

    static computed = {
        showList() {
            let flag = this.data.get('isNav');
            let sourceData = this.data.get('list');
            let list = flag ? (sourceData?.length ? sourceData : this.data.get('initList'))
                : this.data.get('list').slice(0, 6);
            const contentWidth = this.data.get('width');
            const isFund = this.data.get('params')[this.data.get('tagIndex')].type === 'fund';
            const isForeign = this.data.get('params')[this.data.get('tagIndex')].type === 'foreign';
            // 当宽度小于 414 时，影藏第三列数据
            const hide3Col = contentWidth <= this.data.get('middleWidth');
            const headers = {
                left: {style: 'header', text: '代码/名称'},
                right: [
                    {style: 'header', text: isFund ? '单位净值' : '最新价'},
                    isFund ? '' : hide3Col ? '' : {style: 'header', text: '涨跌额'},
                    {style: 'header', text: isFund ? '日涨跌幅' : '涨跌幅'},
                ],
            };
            const newList = [headers];
            list.forEach(item => {
                const {name, market, code, type, price, outMarketInfo = {}, status, ratio, increase, exchange} = item;
                newList.push({
                    left: {
                        style: 'first',
                        name,
                        market,
                        code,
                        type,
                        exchange,
                    },
                    right: [
                        {
                            style: 'default',
                            text: price || '--',
                            subText: outMarketInfo.price,
                        },
                        isFund ? '' : hide3Col ? '' : {
                            style: 'default',
                            text: increase,
                            subText: outMarketInfo.increase,
                            status,
                        },
                        {
                            style: 'btn',
                            text: ratio || '--',
                            subText: outMarketInfo.ratio,
                            status,
                            other: outMarketInfo.text,
                        },
                    ],
                });
            });

            return newList;
        },

        // 是否显示自选推荐股票
        showSelfRecommentStock() {
            return _.get(this.data.get('recStockList'), 'length');
        },
    }

    inited() {
        let flag = this.data.get('isNav');
        if (!flag) {
            this.watch('selfStockList', val => {
                this.data.set('isLoading', false);
                this.data.set('list', val[this.data.get('curType') === 'futures' ? 'fut' : this.data.get('curMarket')]);
            });
            if (this.data.get('curMarket') === 'all') {
                this.data.set('list', this.data.get('initList'));
            }
        }
        else {
            this.watch('allSelfStockData', val => {
                this.data.set('isLoading', false);
                this.data.set('list', val);
            });
        }
    }

    attached() {
        const {quotationMarket} = this.data.get('curPageParams');
        const tagList = this.data.get('params');
        const index = tagList.findIndex(i => i.market === quotationMarket);
        if (index > 0 && this.ref('selfCapsuleTabs')) {
            this.ref('selfCapsuleTabs').updateActiveIndex(index);
            this.tagChange({index});
        }
    }

    tagChange({index}) {
        this.data.set('tagIndex', index);
        const param = this.data.get('params')[index];
        this.data.set('curMarket', param.market ? param.market : (param.type !== 'futures' ? 'all' : ''));
        this.data.set('curType', param.type);
        this.data.set('isLoading', true);
        this.$store.dispatch('getSelfStockLists', param);
    }

    getColor(status) {
        return status === '1' ? 'stock-color-red' : status === '-1' ? 'stock-color-green' : 'stock-color-flat';
    }

    getBgColor(status) {
        return status === '1' ? 'stock-bg-red' : status === '-1' ? 'stock-bg-green' : 'stock-bg-flat';
    }

    searchFocus(e) {
        e = e || window.event;
        e.stopPropagation();
        this.$store.dispatch('changeSearchInputFocus', true);
    }

    clickItem(data) {
        this.$store.dispatch('changeSideNav', false);
        const {name, market, code, type} = data.left;
        if (market && code && type) {
            this.$router.push({
                path: `/${type}/${market}-${code}`,
                query: {
                    name: encodeURIComponent(name),
                },
            });
        }
    }

    toSearch() {
        this.$store.dispatch('changeSearchInputFocus', true);
    }

    titleClick() {
        if (!this.data.get('loginInfo').isLogin) {
            this.$login();
            return;
        }
        const query = {};
        if (this.data.get('curMarket') !== 'all') {
            query.market = this.data.get('curMarket');
            query.type = this.data.get('curType');
        }
        this.$router.push({
            path: '/my/favorite',
            query,
        });
    }

    goSelf() {
        this.$store.dispatch('changeSideNav', false);
        this.$router.push('/my/favorite');
    }
}

export default connect.san({
    loginInfo: 'loginInfo',
    selfStockList: 'selfStockList',
    allSelfStockData: 'allSelfStockData',
    recStockList: 'recStockList',
    curPageParams: 'curPageParams',
})(SelfStockList);
</script>

<style lang="less" scoped>
.self-stock-container[data-s-645d4680] {
    .s-capsule-tabs-scroll {
        width: 100%;
        position: relative;
        margin-top: .06rem;

        .tag-box {
            padding-right: .32rem;
        }

        .right-mask {
            position: absolute;
            right: 0;
            top: 0;
            width: .08rem;
            height: .39rem;
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 25%, #fff 100%);
        }
    }

    .list {
        .row {
            margin: 0 -.24rem;
            cursor: pointer;

            &:not(:first-of-type):hover {
                background: rgba(65, 109, 249, .04);
            }

            .row-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: .64rem;
                box-shadow: inset 0 -1px 0 0 rgba(224, 224, 224, .3);
                font-size: .13rem;

                .row-left,
                .s-row-left {
                    min-width: .63rem;
                    flex-grow: 1;

                    .name {
                        font-weight: 500;
                    }

                    .market-code {
                        margin-top: .02rem;
                        display: flex;
                        align-items: center;
                    }

                    .item-header {
                        color: #525352;
                    }
                }

                .s-row-left {
                    min-width: .23rem;
                }

                .row-right {
                    display: flex;
                    justify-content: space-between;

                    .item-header {
                        text-align: right;
                        color: #525352;
                    }

                    .row-item {
                        width: .8rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        margin-left: 32px;

                        .text {
                            text-align: right;
                        }
                    }

                    .row-item:last-of-type {
                        width: 1.02rem;
                        align-items: flex-end;

                        .btn {
                            border-radius: .06rem;
                            height: .28rem;
                            display: flex;
                            align-items: center;
                            padding-right: .02rem;

                            .icon {
                                font-size: .2rem;
                                line-height: .2rem;
                            }
                        }

                        .btn-bottom {
                            .pan {
                                line-height: .1rem;
                                padding: 0 .02rem;
                                border-radius: .04rem;
                            }
                        }

                        .stock-bg-red {
                            background-color: rgba(255, 51, 51, .1);
                        }

                        .stock-bg-green {
                            background-color: rgba(0, 176, 90, .1);
                        }

                        .stock-bg-flat {
                            background-color: rgba(133, 133, 133, .1);
                        }
                    }
                }
            }
        }

        .row:first-of-type {
            .row-inner {
                height: .32rem;
                cursor: auto;
            }
        }
    }

    .loading {
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .self-stock-error-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        padding-bottom: 1.42rem;

        .icon {
            font-size: .9rem;
            line-height: .9rem;
            color: #e9e9e9;
        }
    }

    .bottom-control {
        height: .64rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .inner {
            display: flex;
            align-items: center;

            .icon {
                font-size: .18rem;
            }

            &:hover {
                .icon,
                .text {
                    color: #416df9;
                }
            }
        }
    }

    .title-in-nav {
        font-size: .18rem;
        font-weight: 500;
        margin: .24rem 0 .15rem .24rem;
        cursor: pointer;
        width: .75rem;
    }
}
</style>
