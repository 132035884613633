<template>
    <div class="add-stock-container" data-s-44e9bde8>
        <template s-if="shape === 'icon'" data-s-44e9bde8>
            <i s-if="!isAdd" class="s-icon add icon c-color" on-click="changeStock($event, true)" data-s-44e9bde8></i>
            <i s-else class="s-icon add-done icon" on-click="changeStock($event, false)" data-s-44e9bde8></i>
        </template>
        <template s-if="shape === 'btn'" data-s-44e9bde8>
            <s-button s-if="!isAdd" type="primary" has-slot height="small" min-width="94" text="加自选" on-click="changeStock(false, true)" data-s-44e9bde8>
                <i class="s-icon btn-add-icon" data-s-44e9bde8></i>
            </s-button>
            <s-button s-else type="default" text="已添加" min-width="94" height="small" has-slot style="color: #858585;" on-click="changeStock(false, false)" data-s-44e9bde8>
                <i class="s-icon btn-done-icon c-color-gray" data-s-44e9bde8></i>
            </s-button>
        </template>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 添加自选股
 */

import {Component, DataTypes} from 'san';
import {addStock, delStock, clickLogAjax} from '@/api';
import {connect} from '@/lib/Store';
import Button from '@/components/button/button';

class AddStock extends Component {
    static components = {
        's-button': Button,
    }

    static dataTypes = {
        isAdd: DataTypes.bool,
        code: DataTypes.string.isRequired,
        type: DataTypes.string.isRequired,
        market: DataTypes.string.isRequired,
        shape: DataTypes.oneOf(['icon', 'btn']),
        // 是否更新自选列表
        needRefresh: DataTypes.bool,
    };

    initData() {
        return {
            shape: 'icon',
            needRefresh: false,
            isRequesting: false,
        };
    }

    changeStock(e, isAdd) {
        e && e.stopPropagation();
        const isRequesting = this.data.get('isRequesting');
        if (!isRequesting) {
            const {code, type, market, loginInfo} = this.data.get();
            if (!loginInfo.isLogin) {
                this.$login();
                return;
            }
            this.data.set('isRequesting', true);
            const params = {code, type, market};
            if (isAdd) {
                clickLogAjax({
                    'scene': 'sug',
                    'action': 'add_self_select',
                    'object': {
                        'type': type,
                        'name': code,
                    },
                });
                addStock([params]).then(() => {
                    this.data.set('isAdd', true);
                    this.fire('add-success', params);
                    this.fire('change', true);
                    this.updateSelf();
                    this.data.set('isRequesting', false);
                });
            }
            else {
                delStock([params]).then(() => {
                    this.data.set('isAdd', false);
                    this.fire('clear-success', params);
                    this.fire('change', true);
                    this.updateSelf();
                    this.data.set('isRequesting', false);
                });
            }
        }
    }

    updateSelf() {
        const {type, market, needRefresh, curPage} = this.data.get();
        if (needRefresh && curPage === 'home') {

            // 首页更新有问题，自选那块的tab没在store里导致更新的不对
            location.reload();
        }
        else if (needRefresh && curPage === 'myFavorite') {
            this.$store.dispatch('setUpdateSelfList', Date.now());
        }
        this.$store.dispatch('getAllSelfStockData', {});
    }
}
export default connect.san({
    loginInfo: 'loginInfo',
    curPage: 'curPage',
})(AddStock);
</script>

<style lang="less" scoped>
.add-stock-container[data-s-44e9bde8] {
    text-align: right;

    .icon {
        font-size: .2rem;
    }

    .btn-add-icon {
        font-size: .16rem;
        color: #fff;
    }

    .add-done {
        color: #b8b8b8;
    }

    .btn-done-icon {
        font-size: .16rem;
    }

    .add:hover {
        color: #416df9;
    }
}
</style>
