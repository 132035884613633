/**
 * 请求路径
 */

// 默认线上地址
let OPEN_API = '/opendata';
let FINANCE_API = 'https://finance.pae.baidu.com';

// 测试环境的配置改下边会触发测试流水线
const isTest = process.env.ENV_TYPE === 'qa';

if (isTest) {
    OPEN_API = '/opendata';
    FINANCE_API = '/proxyapi';
}

module.exports = {
    OPEN_API,
    FINANCE_API,
};
