<template>
    <div s-if="show" class="modal-container" data-s-0228faae>
        <div class="modal" data-s-0228faae>
            <div s-if="showCloseIcon" class="close" on-click="cancel" data-s-0228faae>
                <s-icon type="close" data-s-0228faae></s-icon>
            </div>
            <div class="title c-color {{isSmallTitle ? 'custom-title' : 'c-font-title02'}}" data-s-0228faae>{{title}}</div>
            <div class="desc c-font-normal c-color-gray c-gap-top-small" data-s-0228faae>{{desc}}</div>
            <template s-if="isSingleBtn" data-s-0228faae>
                <div class="single-button-btn c-gap-top-middle" data-s-0228faae>
                    <div class="button-box" data-s-0228faae>
                        <s-button block="{{true}}" on-click="confirm" class="confirm-btn c-font-normal" data-s-0228faae>{{confirmText}}</s-button>
                    </div>
                </div>
            </template>
            <template s-else data-s-0228faae>
                <div class="bottom-btn c-gap-top-middle" data-s-0228faae>
                    <div class="button-box" data-s-0228faae>
                        <s-button block="{{true}}" on-click="cancel" class="cancel-btn c-font-normal c-color" data-s-0228faae>{{cancelText}}</s-button>
                    </div>
                    <div class="button-box c-gap-left" data-s-0228faae>
                        <s-button block="{{true}}" on-click="confirm" class="confirm-btn c-font-normal" data-s-0228faae>{{confirmText}}</s-button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 通用对话框
 */

import {Component, DataTypes} from 'san';
import {Button} from 'santd';
import {Icon} from 'santd';

export default class Modal extends Component {
    static components = {
        's-button': Button,
        's-icon': Icon,
    }

    static dataTypes = {
        show: DataTypes.bool,
        cancelText: DataTypes.string,
        confirmText: DataTypes.string,
    }

    initData() {
        return {
            cancelText: '取消',
            confirmText: '确认',

            // 是否仅有一个底部居中按钮
            isSingleBtn: false,
            // 是否展示关闭 icon
            showCloseIcon: false,
            // 是否小字号标题
            isSmallTitle: false,
        };
    }

    confirm() {
        this.data.set('show', false);
        this.fire('confirm');
    }

    cancel() {
        this.data.set('show', false);
        this.fire('cancel');
    }
}
</script>

<style lang="less" scoped>
.modal-container[data-s-0228faae] {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);

    .modal {
        position: relative;
        width: 4.2rem;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: .1rem;
        padding: .28rem .36rem .24rem .36rem;

        .title {
            line-height: .26rem;
        }

        .desc {
            line-height: .22rem;
        }

        .bottom-btn {
            display: flex;
            justify-content: flex-end;

            .button-box {
                min-width: .78rem;

                .confirm-btn {
                    height: .36rem;
                    background-color: #416df9;
                    border-color: transparent;
                    color: #fff;
                }

                .cancel-btn {
                    height: .36rem;
                    border-color: transparent;
                    background-color: #f5f5f5;
                }
            }
        }

        .single-button-btn {
            display: flex;
            justify-content: center;

            .button-box {
                min-width: 1.08rem;

                .confirm-btn {
                    height: .36rem;
                    background-color: #416df9;
                    border-color: transparent;
                    color: #fff;
                }
            }
        }
    }

    .close {
        position: absolute;
        top: .1rem;
        right: .1rem;
        font-size: .16rem;
        width: .25rem;
        height: .25rem;
    }

    .custom-title {
        font-size: 14px;
    }
}
</style>
