<template>
    <div data-s-1944938f>
        <div data-s-1944938f>
            <div on-click="mainClick" id="box" on-mousemove="move" data-s-1944938f>
                <div id="nav" data-s-1944938f>
                    <s-main-header on-event-reset-x="resetPageX" data-s-1944938f></s-main-header>
                </div>
                <div id="main" on-scroll="move" data-s-1944938f></div>
                <s-main-footer s-if="showFooter" on-disclaimers-click="disclaimersClick" data-s-1944938f></s-main-footer>
            </div>
            <s-main-side-nav data-s-1944938f></s-main-side-nav>
        </div>
        <div s-if="isDisclaimersShow" class="disclaimers-box" data-s-1944938f>
            <div class="disclaimers-inner" data-s-1944938f>
                <div class="disclaimers-title" data-s-1944938f>免责声明</div>
                <div class="disclaimers-content" data-s-1944938f>        {{des}}</div>
                <div class="disclaimers-btn" on-click="disclaimersClose" data-s-1944938f>我知道了</div>
            </div>
        </div>
    </div>
</template>

<script>
import {Component} from 'san';
import {connect} from '@/lib/Store';
import MainHeader from '@/components/main-header/main-header';
import MainFooter from '@/components/main-footer/main-footer';
import MainSideNav from '@/components/main-side-nav/main-side-nav';

class layout extends Component {
    static components = {
        's-main-header': MainHeader,
        's-main-footer': MainFooter,
        's-main-side-nav': MainSideNav,
    }

    initData() {
        return {
            pageX: 10,
            isDisclaimersShow: false,
            des: '香港市场的「基本报价服务」数据内容旨在供投资者作'
                    + '参考用途。百度股市通和香港交易所资讯服务有限公'
                    + '司、其控股公司及 / 或该等控股公司的任何附属公司均'
                    + '竭力确保所提供信息的准确和可靠度，但不能保证其绝'
                    + '对准确和可靠，且亦不会承担因任何不准确或遗漏而引'
                    + '起的任何损失或损害的责任（不管是否侵权法下的责任'
                    + '或合约责任又或其他责任）。',
        };
    }

    attached() {
        let that = this;
        window.addEventListener('scroll', function (e) {
            if (that.data.get('pageX') > 340) {
                that.$store.dispatch('changeSideNav', false);
            }
        });
    }

    mainClick() {
        this.data.set('pageX', 10);
        this.$store.dispatch('changeSideNav', false);
    }

    move(e) {
        if (!this.data.get('sideNavLeft')) {
            this.data.set('pageX', e.pageX);
        }
    }
    resetPageX() {
        this.data.set('pageX', 10);
    }
    disclaimersClick() {
        this.data.set('isDisclaimersShow', true);
    }

    disclaimersClose() {
        this.data.set('isDisclaimersShow', false);
    }
};
export default connect.san({
    showFooter: 'showFooter',
    sideNavLeft: 'sideNavLeft',
})(layout);
</script>

<style lang="less" scoped>
#nav[data-s-1944938f] {
    width: 100%;
    height: .72rem;
    background-color: #F8F8F8;
}

#main[data-s-1944938f] {
    min-height: calc(100vh - 72px - 126px);
}

.disclaimers-box[data-s-1944938f] {
    position: fixed;
    background: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .disclaimers-inner {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -2.1rem;
        margin-top: -1.44rem;
        width: 4.2rem;
        height: 2.88rem;
        padding: .24rem;
        background: #fff;
        border-radius: .1rem;

        .disclaimers-title {
            font-size: .18rem;
            color: #1f1f1f;
            font-weight: 500;
            margin-bottom: .16rem;
        }

        .disclaimers-content {
            font-size: .14rem;
            color: #858585;
            margin-bottom: .16rem;
        }

        .disclaimers-btn {
            float: right;
            background: #416df9;
            border-radius: .1rem;
            width: .78rem;
            height: .36rem;
            text-align: center;
            line-height: .36rem;
            color: #fff;
            cursor: pointer;
        }
    }
}
</style>