
/**
 * 重定向 旧路由 内定义的路由页面到新的路由规则
 * @author v_zhaoxinggang@baidu.com
 */

import {router} from 'san-router';

// 需要重定向的页面page和描述集合
const needRedirectPageMap = {
    detail: '详情页',
    plate: '板块列表页',
    self: '自选列表页',
    list: '榜单列表页',
};

export const checkIsRedirect = page => Object.keys(needRedirectPageMap).includes(page);

export const redirect = (page, query = {}) => {
    let path = '';
    switch (page) {
        case 'detail':
            const {type, market, code} = query;
            path = `/${type}/${market}-${code}`;
            break;
        case 'plate':
            const {market: blockListMarket, type: blockListType} = query;
            path = `/blocklist/${blockListMarket}-${blockListType}`;
            break;
        case 'self':
            path = '/my/favorite';
            break;
        case 'list':
            const {market: topMarket, type: topType} = query;
            path = `/top/${topMarket}-${topType}`;
            break;
        default:
            path = '/';
            break;
    }
    router.locator.redirect(path);
};
