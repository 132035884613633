<template>
    <div class="{{ inputVal ? 'has-input-val' : ''}}" on-click="stop" data-s-08b5b39c>
        <div class="
                search-container
                {{inputFocus ? 'input-focus' : ''}}
                {{containerWidth <= 650 ? 'small-screen' : ''}}
            " data-s-08b5b39c>
            <label class="search-icon" for="search" on-mousedown="preventHandler" data-s-08b5b39c>
                <i class="icon s-icon" data-s-08b5b39c></i>
            </label>
            <input s-ref="input" id="search" type="text" class="input" value="{= inputVal =}" placeholder="股票代码/名称" autocomplete="off" on-focus="inputFocusHandler" on-blur="inputBlurhandler" on-input="inputChangeHandler" data-s-08b5b39c>
            <div s-if="inputFocus" class="search-result-wrap" on-mousedown="preventHandler" data-s-08b5b39c>
                <div s-if="showEmptyHis" class="his-empty" data-s-08b5b39c>
                    <i class="s-icon icon" data-s-08b5b39c></i>
                    <div class="title c-gap-top-middle" data-s-08b5b39c>暂无搜索记录</div>
                    <div class="desc c-font-small" data-s-08b5b39c>试试搜索股票名称或代码吧</div>
                </div>

                <div s-if="showHis" class="his-list" data-s-08b5b39c>
                    <div class="header c-gap-bottom-tiny c-gap-top-lh" data-s-08b5b39c>
                        <div class="title" data-s-08b5b39c>历史搜索</div>
                        <i class="clear-icon s-icon c-color" on-click="removeHisStorage" data-s-08b5b39c></i>
                    </div>
                    <div class="his-content" data-s-08b5b39c>
                        <span s-for="item in historyList" class="
                                his-item
                                c-font-small
                                c-gap-inner-top-small
                                c-gap-inner-bottom-small
                                c-gap-inner-left-middle
                                c-gap-inner-right-middle
                                c-gap-left
                                c-gap-bottom-middle
                                c-color-gray
                            " on-click="historyClick(item)" data-s-08b5b39c>{{item}}</span>
                    </div>
                </div>

                <div s-if="showRes" class="result-list" data-s-08b5b39c>
                    <s-capsule-tabs titleList="{{resFilterTags}}" class="c-gap-top-small c-gap-left c-gap-bottom-small" on-event-click="filterStock" data-s-08b5b39c></s-capsule-tabs>
                    <div class="list-item-wrap" s-if="list.length" data-s-08b5b39c>
                        <div s-for="item in list" class="list-item c-gap-inner-right c-gap-inner-left" on-click="clickItem(item)" data-s-08b5b39c>
                            <div class="list-item-content c-gap-inner-top-small c-gap-inner-bottom-small" data-s-08b5b39c>
                                <div class="item-left" data-s-08b5b39c>
                                    <div class="name c-font-normal s-line-clamp1" data-s-08b5b39c>
                                        <!--bca-disable-next-line-->
                                        {{item.name | highlight | safeCheck | raw}}
                                    </div>
                                    <div class="market-code" data-s-08b5b39c>
                                        <s-market-label s-if="{{item.exchange}}" market="{{item.exchange}}" data-s-08b5b39c></s-market-label>
                                        <span class="code c-font-small {{item.exchange ? 'c-gap-left-tiny' : ''}}" data-s-08b5b39c>
                                            {{item.code}}
                                        </span>
                                    </div>
                                </div>
                                <div class="item-right" data-s-08b5b39c>
                                    <div class="price c-font-normal c-color" data-s-08b5b39c>{{item.price}}</div>
                                    <div class="ratio c-font-normal {{color(item.status)}}" data-s-08b5b39c>{{item.ratio}}</div>
                                    <div class="add-stock" data-s-08b5b39c>
                                        <s-add-stock is-add="{{item.follow_status === '1'}}" code="{{item.code}}" type="{{item.type}}" market="{{item.market}}" name="{{item.name}}" need-refresh="{{true}}" data-s-08b5b39c></s-add-stock>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div s-else-if="isLoading" class="laoding" data-s-08b5b39c>
                        <s-spin data-s-08b5b39c></s-spin>
                    </div>
                    <div s-if="showNoData" class="data-empty" data-s-08b5b39c>
                        <i class="s-icon icon" data-s-08b5b39c></i>
                        <div class="title c-font-normal" data-s-08b5b39c>暂无搜索内容</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 搜索组件
 */

import {Component, DataTypes} from 'san';
import {connect} from '@/lib/Store';
import {Link} from 'san-router';
import MarketLabel from '@/components/market-label/market-label';
import AddStock from '@/components/add-stock/add-stock';
import CapsuleTabs from '@/components/capsule-tabs/capsule-tabs';

import {search, getSearchHis, addStock, clickLogAjax} from '@/api';

import storage from '@/utils/storage';
import {Spin} from 'santd';
import safeCheck from '@/filters/safeCheck';

import _ from 'lodash';

class FinanceSearch extends Component {
    static components = {
        'router-link': Link,
        's-market-label': MarketLabel,
        's-add-stock': AddStock,
        's-capsule-tabs': CapsuleTabs,
        's-spin': Spin,
    }

    initData() {
        return {
            inputVal: '',
            prevInputVal: '',
            searchResult: [],
            inputFocus: false,
            historyList: [],
            resFilterTags: ['综合', '股票/ETF', '指数/板块', '基金', '外汇', '期货'],
            filterId: 0,
            isLoading: false,
            noData: false,
        };
    }

    static computed = {

        // 显示暂无历史记录的空白页
        showEmptyHis() {
            return !this.data.get('inputVal') && !this.data.get('historyList').length;
        },

        // 是否显示搜索结果
        showRes() {
            return this.data.get('inputVal');
        },

        // 显示历史记录
        showHis() {
            return !this.data.get('inputVal') && this.data.get('historyList').length;
        },

        // 显示没有数据的空页面
        showNoData() {
            return this.data.get('inputVal') && this.data.get('noData') && !this.data.get('isLoading');
        },

        // 筛选需要显示的列表数据
        list() {
            const type = [
                'all',
                ['stock'],
                ['index', 'block'],
                ['fund'],
                ['foreign'],
                ['futures'],
            ][this.data.get('filterId')] || 'all';
            const res = this.data.get('searchResult');
            if (type === 'all') {
                return res;
            }
            return res.filter(item => type.includes(item.type));
        },
    }

    static filters = {
        highlight(val) {
            const ipt = this.data.get('inputVal');
            if (ipt && val) {
                return val.replace(new RegExp(ipt), `<em class="highlight">${ipt}</em>`);
            }
            return val;
        },
        safeCheck,
    }

    color(status) {
        return +status === 1 ? 'stock-color-red' : +status === -1 ? 'stock-color-green' : '';
    }

    inited() {
        this.data.set('historyList', this.getHisStorage());
        // 获取本地缓存历史，如果本地没有，将接口的历史存入缓存，如果本地有直接使用本地
        let historyList = this.getHisStorage() || [];
        getSearchHis().then(res => {
            if (!historyList.length && res.list && res.list.length) {
                storage.set('searchHistory', res.list);
                this.data.set('historyList', this.getHisStorage());
            }
        });
        this.watch('inputFocus', val => {
            if (val) {
                this.ref('input').focus();
            }
            else {
                this.ref('input').blur();
            }
        });
    }

    inputFocusHandler(e) {
        this.$store.dispatch('changeSearchInputFocus', true);
    }

    inputBlurhandler() {
        this.$store.dispatch('changeSearchInputFocus', false);
        this.data.set('inputVal', '');
        this.data.set('searchResult', []);
    }

    inputChangeHandler = _.debounce(() => {
        this.data.set('noData', false);
        const value = this.data.get('inputVal');
        const prevVal = this.data.get('prevInputVal');
        this.data.set('searchResult', []);

        // 如果搜索词相同或者为空字符则不进行搜索
        if (!value) {
            return;
        }
        this.data.set('isLoading', true);
        search({
            wd: value,
            skip_login: 1,
        }).then(res => {
            this.data.set('isLoading', false);
            this.data.set('searchResult', res);
            this.data.set('prevInputVal', value);
            this.data.set('filterId', 0);
            if (res.length) {
                this.setHisStorage();
            }
            this.data.set('noData', !res.length);
            this.data.set('historyList', this.getHisStorage());
        }).catch(() => {
            this.data.set('isLoading', false);
            this.data.set('filterId', 0);
            this.data.set('searchResult', []);
            this.data.set('noData', true);
        });
    }, 300)

    filterStock(e) {
        this.data.set('filterId', e.index);
    }

    setHisStorage() {
        const value = this.data.get('inputVal');
        let historyList = this.getHisStorage();

        if (historyList && Array.isArray(historyList)) {
            historyList.unshift(value);
        }
        else {
            historyList = [value];
        }

        historyList = historyList.filter(i => !!i);

        // 保留十条，并去重
        historyList = _.uniq(historyList.splice(0, 10));

        storage.set('searchHistory', historyList);
    }

    getHisStorage() {
        return storage.get('searchHistory') || [];
    }

    removeHisStorage() {
        storage.remove('searchHistory');
        this.data.set('historyList', this.getHisStorage());
    }

    preventHandler(e) {
        e = e || window.event;
        e.preventDefault();
    }

    stop(e) {
        e = e || window.event;
        e.stopPropagation();
    }

    historyClick(word) {
        this.data.set('inputVal', word);
        this.inputChangeHandler();
    }

    clickItem(data) {
        const {market, code, type, name} = data;
        clickLogAjax({
            'scene': 'sug',
            'action': 'goto_details_page',
            'object': {
                'type': type,
                'name': code,
            },
        });
        this.$store.dispatch('changeSearchInputFocus', false);
        this.data.set('inputVal', '');
        if (document.documentElement.style.overflow === 'hidden') {
            this.fire('search-to-super', {market, code, type, name});
        } else {
            this.$router.push({
                path: `/${type}/${market}-${code}`,
                query: {
                    name: encodeURIComponent(name),
                },
            });
        }
    }
}

export default connect.san({
    inputFocus: 'searchInputFocus',
})(FinanceSearch);
</script>

<style lang="less" scoped>
.has-input-val[data-s-08b5b39c] {
    border: 1px solid rgba(65, 109, 249, .16);
    border-radius: .1rem;
}

.search-container[data-s-08b5b39c] {
    position: relative;
    width: 4.22rem;
    height: .4rem;
    background: #f5f5f5;
    border-radius: .1rem;
    padding: 0 .12rem;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    border: 1px solid transparent;

    &.input-focus {
        width: 5.24rem;
        border: 1px solid #416df9;
    }

    &.small-screen {
        width: 3.62rem;

        &.input-focus {
            width: 3.62rem;
            border: 1px solid #416df9;
        }
    }

    &:hover {
        border: 1px solid #416df9;
    }

    .search-icon {
        flex-shrink: 0;
        margin-right: 6px;

        .icon {
            font-size: .2rem;
        }
    }

    .input {
        flex-grow: 1;
        font-size: .14rem;
        color: #1f1f1f;
        padding: .1rem .02rem;
        background-color: transparent;

        &::placeholder {
            font-size: .14rem;
            color: #1f1f1f;
        }
    }

    .search-result-wrap {
        position: absolute;
        right: 0;
        z-index: 1;
        top: .52rem;
        width: 5.24rem;
        height: 3.6rem;
        box-shadow:
            0 2px 4px -1px rgba(0, 0, 0, .04),
            0 1px 10px 0 rgba(0, 0, 0, .05),
            0 4px 5px 0 rgba(0, 0, 0, .06);
        border-radius: .1rem;
        background-color: #fff;

        .his-empty {
            margin: .4rem auto 0;
            display: flex;
            width: 2.8rem;
            height: 2.8rem;
            flex-direction: column;
            align-items: center;

            .icon {
                margin-top: .56rem;
                font-size: .9rem;
                line-height: .9rem;
                color: #e9e9e9;
            }

            .title {
                text-align: center;
                margin-bottom: .06rem;
                color: #1f1f1f;
            }

            .desc {
                text-align: center;
                color: #858585;
            }
        }

        .his-list {
            .header {
                padding: 3px 16px;
                height: .36rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-weight: 500;
                    color: #1f1f1f;
                    font-size: .16rem;
                }

                .clear-icon {
                    font-size: .14rem;
                }
            }

            .his-content {
                display: flex;
                flex-wrap: wrap;

                .his-item {
                    flex-shrink: 0;
                    background: #f5f5f5;
                    border-radius: .14rem;
                    cursor: pointer;
                }
            }
        }

        .result-list {
            .list-item-wrap {
                max-height: 2.92rem;
                overflow-y: auto;

                .list-item {
                    cursor: pointer;

                    .list-item-content {
                        display: flex;
                        justify-content: space-between;
                        box-shadow: inset 0 -1px 0 0 rgba(224, 224, 224, .3);

                        .item-left {
                            .name {
                                font-weight: 500;
                                color: #1f1f1f;
                                max-width: 2.3rem;

                                .highlight {
                                    color: #416df9;
                                    font-style: normal;
                                }
                            }

                            .market-code {
                                display: flex;
                                margin-top: .06rem;
                                align-items: center;

                                .code {
                                    color: #525252;
                                }
                            }
                        }

                        .item-right {
                            display: flex;
                            align-items: center;

                            .add-stock {
                                margin-left: .55rem;
                            }

                            .ratio {
                                width: 1.08rem;
                                text-align: right;
                            }
                        }
                    }
                }

                .list-item:hover {
                    background: rgba(65, 109, 249, .04);
                }
            }

            .laoding {
                height: 3.2rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .data-empty {
                margin-top: .47rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                .icon {
                    font-size: .9rem;
                    color: #e9e9e9;
                    line-height: .9rem;
                }

                .title {
                    color: #1f1f1f;
                    margin-top: .18rem;
                }
            }
        }
    }
}
</style>
