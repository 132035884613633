export const tabList = [
    {text: '热股', type: 'stock'},
    {text: '热搜', type: 'search'},
    {text: '板块', type: 'plate'},
    {text: '舆情', type: 'publicOpinion'},
];

export const marketOptions = [
    {value: '', label: '全部'},
    {value: 'ab', label: 'A股'},
    {value: 'hk', label: '港股'},
    {value: 'us', label: '美股'},
];

export const timeOptions = [
    {value: 'hour', label: '1小时'},
    {value: 'day', label: '今日'},
];


export const financeTypeOptions = [
    {value: '', label: '全部'},
    {value: 'stock', label: '股票'},
    {value: 'index', label: '指数'},
    {value: 'block', label: '板块'},
];

export const marketMap = {
    'HKM': 'hk',
    'HKG': 'hk',
    'XHKG-M': 'hk',
    'XHKG-G': 'hk',
    'SS': 'ab',
    'SZ': 'ab',
    'SH': 'ab',
    'XSHE': 'ab',
    'XSHG': 'ab',
    'O': 'us',
    'XNAS': 'us',
    'XNYS': 'us',
    'N': 'us',
};