/**
 * @file storage.js
 * @author v_zhaoxinggang
 * @desc 金融 pc 站本地缓存
 */

import {sendError} from '@/utils/monitor';

// 已有缓存 key 值名单
const storageKeys = {
    // 历史记录缓存
    searchHistory: 'FINANCE_PC_SEARCH_HISTORY',
    // rightpaddle用户标记缓存
    rightpaddleUserToken: 'RIGHTPADDLEUSERTOKEN',
};

const storage = {
    set(key, value) {
        try {
            localStorage.setItem(storageKeys[key], JSON.stringify(value));
            return true;
        }
        catch (err) {
            sendError({
                key,
                msg: 'set-storage',
            });
        }
    },

    get(key) {
        const res = localStorage.getItem(storageKeys[key]);
        try {
            return JSON.parse(res);
        }
        catch (err) {
            sendError({
                key,
                msg: 'get-storage',
            });
        };
    },

    remove(key) {
        try {
            localStorage.removeItem(storageKeys[key]);
            return true;
        }
        catch (err) {
            sendError({
                key,
                msg: 'remove-storage',
            });
        }
    },
};

export default storage;
