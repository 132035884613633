// 函数柯里化
export function curry(fn, ...args) {
    const length = fn.length;
    args = args || [];
    return function (...newArgs) {
        newArgs = args.concat(newArgs);
        if (newArgs.length < length) {
            return curry.call(this, fn, newArgs);
        }

        return fn.apply(this, newArgs);

    };
}

export const pipe = (f, g) => (...args) => g.call(this, f.apply(this, args));
export const compose = (...args) => args.reduceRight(pipe, args.shift());
export const newcompose = (...args) => args.reverse().reduce(pipe, args.shift());
// 首字母大写
export const upperFir = word => word?.replace(/\b([\w])(.+)\b/, (word, $1, $2) => `${$1.toUpperCase()}${$2}`);


/**
 *
 * @param {Function} func 函数
 * @param {number} wait 时间 毫秒
 * @param isInitial 是否初始调用
 * @return {Function} throttle
 */
export function throttle(func, wait, isInitial = false) {
    let timer = null;
    let previous = null;

    return function (...argum) {
        const context = this;
        const args = argum;
        const now = Date.now();

        if (!previous && isInitial) {
            func.apply(context, args);
        }

        if (!previous) {
            previous = now;
        }

        const remaining = wait - (now - previous);

        if (remaining <= 0 || remaining > wait) {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }

            previous = now;

            timer = func.apply(context, args);
        }
    };
}


/**
 * 此方法用来解析hash, 将hash转换成数组类型，
 * 例如: /welcome/index 转换成 ['/welcome', 'welcome/index']
 *
 * @param {string} path /welcome/index
 * @returns {array | string}  只有path是'/'的时候，返回的才是string
 */

export const parseOpenKeys = path => {
    if (!path || path === '/') {
        return ['/'];
    }
    let list = path.split('/');
    let arr = [];
    let str = '';
    for (let i = 1; i < list.length; i++) {
        str += `/${list[i]}`;
        arr.push(str);
    }
    return arr;
};

/**
 * 格式化时间格式
 *
 * @param {Date} dt 时间对象
 * @param {string} fmt 格式化规则
 * @return {string} 时间格式化结果
 */
export function format(dt, fmt) {
    const o = {
        'M+': dt.getMonth() + 1,
        'd+': dt.getDate(),
        'h+': dt.getHours(),
        'm+': dt.getMinutes(),
        's+': dt.getSeconds(),
        'q+': Math.floor((dt.getMonth() + 3) / 3),
        'S': dt.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (dt.getFullYear() + '').substr(4 - RegExp.$1.length)
        );
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))
            );
        }
    }
    return fmt;
}

/**
 * getDate
 * 秒转换为日期
 *
 * @param {string} time 秒
 * @return {Date} 日期对象
 */
export function getDate(time) {
    return new Date(time * 1000);
}

export const isUrl = path => {
    // eslint-disable-next-line
    const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

    return reg.test(path);
};

/**
 * 判断是否是IE浏览器
 */
export function isIE() {
    const bw = window.navigator.userAgent;
    const compare = s => bw.indexOf(s) >= 0;
    const ie11 = (() => 'ActiveXObject' in window)();
    return compare('MSIE') || ie11;
}

/**
 * 判断当前环境是否为移动设备
 *
 * @returns {boolean} 如果当前环境为移动设备则返回true，否则返回false
 */
export function isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
}

export function getCurrentBrowser() {
    let ua = navigator.userAgent.toLocaleLowerCase();
    let browserType = null;
    if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
        browserType = 'IE';
    }
    else if (ua.match(/firefox/) != null) {
        browserType = 'firefox';
    }
    else if (ua.match(/ucbrowser/) != null) {
        browserType = 'UC';
    }
    else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
        browserType = 'opera';
    }
    else if (ua.match(/bidubrowser/) != null) {
        browserType = 'baidu';
    }
    else if (ua.match(/metasr/) != null) {
        browserType = 'sougou';
    }
    else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        browserType = 'QQ';
    }
    else if (ua.match(/maxthon/) != null) {
        browserType = 'maxthon';
    }
    else if (ua.match(/chrome/) != null) {
        const is360 = _mime('type', 'application/vnd.chromium.remoting-viewer');
        if (is360) {
            browserType = '360';
        }
        else {
            browserType = 'chrome';
        }
    }
    else if (ua.match(/safari/) != null) {
        browserType = 'Safari';
    }
    else {
        browserType = 'others';
    }
    return browserType;
}

function _mime(option, value) {
    const mimeTypes = navigator.mimeTypes;
    for (const mt in mimeTypes) {
        if (mimeTypes[mt][option] === value) {
            return true;
        }
    }
    return false;
}

/**
 * 格式化时间格式
 *
 * @param {Date} dt 时间对象  通过new Date()把时间戳转化为时间对象
 * @param {string} fmt 格式化规则  例：yyyy-MM-dd、MM-dd、hh:mm
 * @return {string} 时间格式化结果  返回入参的格式化规则
 */
export function formatTime(dt, fmt) {
    const o = {
        'M+': dt.getMonth() + 1,
        'd+': dt.getDate(),
        'h+': dt.getHours(),
        'm+': dt.getMinutes(),
        's+': dt.getSeconds(),
        'q+': Math.floor((dt.getMonth() + 3) / 3),
        'S': dt.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (dt.getFullYear() + '').substr(4 - RegExp.$1.length)
        );
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))
            );
        }
    }
    return fmt;
}

/**
 * 判断是否为今天
 *
 * @param {string} timestamp 时间戳
 * @returns
 */
export function isToday(timestamp) {
    const curDateStamp = new Date().setHours(0, 0, 0, 0);
    const dateStamp = new Date(timestamp * 1000).setHours(0, 0, 0, 0);
    return curDateStamp === dateStamp;
}

/**
 * 格式化路由中的参数 a-b-c 转为 [a, b, c]
 */
export const formatUrlQuery = queryString => queryString.split('-');

/**
 * 格式化详情页路由中的参数 us-BIDU 转为 {market: 'us', code: 'BIDU'}  us-AB-A 转为 [market: 'us', code: 'AB-A']
 *
 * @param {string} marketCode 例如 us-BIDU
 * @returns kv数据
 */
export const formatUrlMarketCode = marketCode => {
    const index = marketCode.indexOf('-');
    return {
        market: marketCode.slice(0, index),
        code: marketCode.slice(index + 1),
    };
};

/**
 * 获取url的query参数并且以对象的形式返回
 *
 * @param {url} url地址
 * @return {string} query对象
 * 例如：getUrlQuery("http://www.baidu.com?key=1&name=5");  可以得到 {key: 1, name: 5}
 */
export function getUrlQuery(url) {
    if (url.indexOf('?') === -1) {
        return {};
    }
    const array = url.split('?').pop().split('&');
    const data = {};
    array.forEach(ele => {
        let dataArr = ele.split('=');
        data[dataArr[0]] = dataArr[1];
    });
    return data;
}

/**
 * 小于10的数据在前方补全0
 * @param {number}
 * @return {string}
 */
export function addZero(data) {
    return data < 10 ? `0${data}` : data;
}


/**
 * 数字转换为 千、万、亿
 * @param {number} 数字
 * @return {string}
 */
export function transform(value) {
    let newValue = ['', '', ''];
    let fr = 1000;
    let num = 3;
    const fm = 1;
    const ad = 1;
    while (value / fr >= 1) {
        fr *= 10;
        num += 1;
    }
    if (num <= 4) { // 千
        newValue[1] = '';
        newValue[0] = value;
    }
    else if (num <= 8) { // 万
        const text1 = '万';
        const fm = '万' === text1 ? 10000 : 10000000;
        newValue[1] = text1;
        newValue[0] = (value / fm).toFixed(2) + '';
    }
    else if (num <= 16) { // 亿
        let text1 = '亿';
        let fm = 100000000;
        newValue[1] = text1;
        newValue[0] = (value / fm).toFixed(2) + '';
    }
    if (value < 1000) {
        newValue[1] = '';
        newValue[0] = value + '';
    }
    return newValue.join('');
}

/**
 * 获取某个对象/数组深层key对应的值（防止深层取值报错）
 *
 * @param {Object} obj 目标对象
 * @param {string} path 查询路径（如：对象 obj = {a:b:[{c:1},{c:2}]，获取obj.a.b[1].c, path为'a.b.1.c'）
 * @return {any} 查询对象存在，则返回key对应的值；不存在则返回undefined
 */
// eslint-disable-next-line consistent-return
export function getDataByPath(obj, path) {
    if (!path) {
        return obj;
    }
    if (typeof obj !== 'object' && !Array.isArray(obj)) {
        // eslint-disable-next-line consistent-return
        return;
    }
    const arr = path.split('.');
    const key = arr.shift();
    const val = obj[key];
    if (val === undefined) {
        // eslint-disable-next-line consistent-return
        return undefined;
    }
    else if (arr.length === 0) {
        return val;
    }
    else if (typeof val === 'object' || Array.isArray(val)) {
        return getDataByPath(val, arr.join('.'));
    }
}

/**
 * 数值添加计量单位
 *
 * @param {number} v 原始数据
 * @param {number} fixed 保留小数位数
 * @return {string} 添加 万亿/亿/万 后的数据
 */
export function setUnit(v, fixed) {
    const fix = isNaN(Number(fixed)) ? 2 : Number(fixed);
    const integ = parseInt(v, 10);
    let unit = '';
    let val = v;
    if (integ / Math.pow(10, 12) > 1) {
        unit = '万亿';
        val = Number(integ / Math.pow(10, 12)).toFixed(fix);
    }
    else if (integ / Math.pow(10, 8) > 1) {
        unit = '亿';
        val = Number(integ / Math.pow(10, 8)).toFixed(fix);
    }
    else if (integ / Math.pow(10, 4) > 1) {
        unit = '万';
        val = Number(integ / Math.pow(10, 4)).toFixed(fix);
    }
    val = formatFloat(val);
    return val + unit;
}

/**
 * 格式化数值，出现xxxx.xx的，转化为xxxx.x
 *
 * @param {number} v 原始数值
 * @return {string} 转化后的数值
 */
export function formatFloat(v) {
    const integLimit = 4;
    const decimalLimit = 2;
    // 整数部分
    let integ = '';
    // 小数部分
    let round = '';
    ('' + v).replace(/(\d+)(.\d+)?/, (word, $1, $2) => {
        integ = $1 || '';
        round = $2 || '';
    });
    if (integ.length + round.length > integLimit + decimalLimit) {
        return Number(v).toFixed(1);
    }
    return v;
}

/**
 * 移除 html 中的骨架屏
 */
export const removeSkeleton = () => {
    const skeletonNode = document.getElementById('app-skeleton');
    skeletonNode && document.getElementById('app').removeChild(skeletonNode);
};
