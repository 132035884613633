/*
* spy 接入参考： https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/IyxPOPFmdj/u5if_ZaIawaCwp
*/

import SpyClient from 'spy-client';
import {getCurrentBrowser} from './util';

const spy = new SpyClient({
    pid: '19_129', // 必须
    lid: '', // 可选，页面的logid
    sample: 0.5, // 可选，默认为1, 全局抽样，取值：[0-1], 所有发送接口都受到该抽样，单个发送接口的sample配置会覆盖该抽样。
});
// 类型：性能，触发时间：500MsAfterOnLoad，说明：performance timing的数据采集基本指标
spy.listenTiming(function (metric) {
    // group 默认 common
    spy.sendPerf({
        sample: 0.5,
        info: metric,
    });
});

// 类型：性能，触发时间：500MsAfterOnLoad
spy.listenLCP(function (metric) {
    spy.sendPerf({
        sample: 0.5,
        info: metric,
    });
});

// 首次输入延迟，衡量首次交互卡顿
spy.listenFID(function (metric) {
    spy.sendPerf({
        sample: 0.5,
        info: metric,
    });
});

/**
 * 发送接口请求耗时
 * @param time 为接口请求时长
 */
function sendRequestTime(time) {
    spy.sendPerf({
        group: 'rTime',
        sample: 0.5,
        // 必须, 指标信息，每个字段为一个指标，由用户自定义，这里的firstScreen等都是业务自己定义，后续会在平台上配置好，平台会从该字段取对应指标信息。
        // 这些指标需要你自行计算好时间再发送，不能带单位
        info: {
            rTime: time,
        },
        // 可选，维度信息，每个字段为一个维度，由用户自定义，这里的netType、pageType都是业务自己定义，后续会在平台上配置好，平台会从该字段取对应维度信息。
        dim: {
            browser: getCurrentBrowser(),
        },
    });
}

/**
 * 发送错误信息
 * @param errorInfo 为对象，至少有一个msg key值
 */
function sendError(errorInfo) {
    let info = {
        msg: 'Default error message.', // msg字段是必须的，必须的，必须的，会统计相同msg的总量
    };
    Object.assign(info, errorInfo);

    spy.sendExcept({
        group: 'error',
        // 必须, 指标信息，每个字段为一个指标，由用户自定义，这里的firstScreen等都是业务自己定义，后续会在平台上配置好，平台会从该字段取对应指标信息。
        // 这些指标需要你自行计算好时间再发送，不能带单位
        info,
        dim: {
            browser: getCurrentBrowser(),
        },
    });
}

function sendJsError(errorInfo) {
    let info = {
        msg: 'Default error message.', // msg字段是必须的，必须的，必须的，会统计相同msg的总量
    };
    Object.assign(info, errorInfo);

    spy.sendExcept({
        // spy平台声明的固定groupId
        group: 'pc_jr_js_error',
        // 必须, 指标信息，每个字段为一个指标，由用户自定义，这里的firstScreen等都是业务自己定义，后续会在平台上配置好，平台会从该字段取对应指标信息。
        // 这些指标需要你自行计算好时间再发送，不能带单位
        info,
        dim: {
            // 获取浏览器信息
            browser: getCurrentBrowser(),
        },
    });
}

/**
 * 如果能拿到error实例，通过该方法快速上报异常，默认会获取stack等信息
 * @param err 为Error 实例
 */
function sendExceptForError(err) {
    spy.sendExceptForError(err, {
        dim: {
            browser: getCurrentBrowser(),
        },
    });
}

/**
 * 分布统计
 */
function sendDist(info) {
    spy.sendDist({
        group: 'gushitong_pc',
        info,
        dim: {
            browser: getCurrentBrowser(),
        },
    });
}

export {
    spy,
    sendRequestTime,
    sendError,
    sendExceptForError,
    sendJsError,
    sendDist,
};