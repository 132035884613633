/**
 * 开发环境bduss报错，线上抽取 1% 发送日志
 * @author yanlili03
*/
import {sendError} from '@/utils/monitor';
const isDev = process.env.ENV_TYPE === 'dev';

function bdussCheck(str) {
    const regBduss = /(bduss=)|(bduss["']\s*:)|(bduss\":)/ig;
    const hasBduss = regBduss.test(str);
    if (Math.random() < 0.01 && hasBduss) {
        sendError({
            msg: 'Return data include bduss',
        });
    }

    if (isDev && hasBduss) {
        throw new Error('bduss is not allowed in data');
    }
}

export default bdussCheck;