<template>
    <div class="wrapper">
        <s-dropdown overlayClassName="dropdown-root" visible="{{isMenuVisible}}" trigger="click">
            <s-menu s-if="isMenuVisible" defaultSelectedKeys="{{defaultSelectedKeys}}" prefixCls="{{prefixCls}}" on-click="capture:handleMenuClick" slot="overlay">
                <s-menu-item s-for="item in selectOptionArr" key="{{item.value}}" disabled="{{item.disabled}}">{{item.label}}</s-menu-item>
            </s-menu>
            <s-button s-if="{{styleType === 'btn'}}" on-click="onBtn($event)">{{defaultValue}}<s-icon type="down"></s-icon></s-button>
            <div s-if="styleType === 'arrow'" on-click="onBtn($event)"><s-icon type="down"></s-icon></div>
        </s-dropdown>
    </div>
</template>
<script>
import {Button, Icon, Dropdown, Menu} from 'santd';
import {DataTypes} from 'san';
import message from 'santd/es/message';
import 'santd/es/message/style';

export default {
    dataTypes: {
        defaultValue: DataTypes.oneOfType([
            DataTypes.string,
            DataTypes.number,
        ]).isRequired,
        selectOptionArr: DataTypes.arrayOf(DataTypes.object).isRequired,
        defaultSelectedKeys: props => {
            if (!props.defaultSelectedKeys) {
                props.defaultSelectedKeys = '0';
            }
        },
        styleType: DataTypes.string,
    },
    initData() {
        return {
            isMenuVisible: false,
            styleType: 'btn',
        };
    },
    attached() {
        const {isMenuVisible} = this.data.get();
        document.addEventListener('click', e => {
            this.data.set('isMenuVisible', false);
        }, false);
    },
    components: {
        's-dropdown': Dropdown,
        's-button': Button,
        's-icon': Icon,
        's-menu': Menu,
        's-menu-item': Menu.Item,
    },
    handleMenuClick(e) {
        const {selectOptionArr, isMenuVisible} = this.data.get();
        selectOptionArr.forEach(item => {
            if (item.value === e.key) {
                this.data.set('defaultValue', item.label);
            }
        });
        this.data.set('isMenuVisible', !isMenuVisible);
        this.fire('handle-menu-click', e);
    },
    onBtn(e) {
        window.event ? window.event.cancelBubble = true : e.stopPropagation();
        const isMenuVisible = !this.data.get('isMenuVisible');
        this.data.set('isMenuVisible', isMenuVisible);
        this.fire('handle-btn-click', isMenuVisible);
        setTimeout(function () {
            let drops = document.getElementsByClassName('santd-dropdown');
            for (let i = 0; i < drops.length; i++) {
                drops[i].parentNode.parentNode.style.zIndex = 11111111;
            }
        }, 1000);
    },
};
</script>
<style lang="less">
.wrapper {
    .santd-btn:not(.santd-btn-circle):not(.santd-btn-circle-outline).santd-btn-icon-only {
        box-sizing: border-box;
        height: .24rem;
        padding: 0 .1rem;
        border-radius: 1rem;
        font-size: .12rem;
        background-color: transparent;
        border: .01rem solid #b8b8b8;
        color: #1f1f1f;

        &:hover {
            color: #416df9;
            border-color: #416df9;
        }

        .santd-icon {
            margin-left: .02rem;
        }
    }
}

.dropdown-root {
    .santd-dropdown-menu {
        position: absolute;
        margin-top: .08rem;
        border-radius: .1rem;
        max-height: 4rem;
        overflow: auto;

        .santd-dropdown-menu-item {
            padding: .13rem .24rem;
            border-radius: .04rem;
        }

        .santd-dropdown-menu-item,
        .santd-dropdown-menu-submenu-title {
            color: #1f1f1f;
        }

        .santd-dropdown-menu-item:hover {
            background-color: #f8f9ff;
        }
    }

    .santd-dropdown-menu::before {
        position: absolute;
        top: -.01rem;
        left: 10%;
        content: "";
        width: 0;
        height: 0;
        font-size: 0;
        display: block;
        border-radius: .03rem;
        border-width: .1rem;
        border-color: #fff #fff transparent transparent;
        border-style: solid solid dashed dashed;
        transform: rotate(-45deg);
        z-index: -99;
    }

    .santd-dropdown-menu-item-selected,
    .santd-dropdown-menu-submenu-title-selected,
    .santd-dropdown-menu-item-selected > a,
    .santd-dropdown-menu-submenu-title-selected > a {
        color: #416df9;
        background-color: transparent;
    }
}
</style>