<template>
    <div class="recommented-stock-container" data-s-4f97313c>
        <div class="list-content c-gap-top-lh c-gap-bottom-lh" style="height: {{showList.rowNum * 160 / 100}}rem;" data-s-4f97313c>
            <div s-for="row, rowIndex in showList.list" class="row-item" data-s-4f97313c>
                <div s-for="item, index in row" class="
                        stock-item
                        c-gap-inner-top-large
                        c-gap-inner-bottom-large
                        c-gap-inner-left-middle
                        c-gap-inner-right-middle
                        {{index === 0 ? '' : 'c-gap-left'}}
                        {{item.selected ? 'selected' : ''}}
                    " on-click="clickItem(item)" data-s-4f97313c>
                    <div class="name c-font-normal c-color c-gap-bottom-small s-line-clamp1" data-s-4f97313c>{{item.name}}</div>
                    <div class="market-code" data-s-4f97313c>
                        <s-market-label market="{{item.exchange}}" data-s-4f97313c></s-market-label>
                        <div class="code c-color-gary2 c-gap-left-lh c-font-small" data-s-4f97313c>{{item.code}}</div>
                    </div>
                    <div class="
                            ratio
                            c-font-normal
                            c-gap-top-large
                            {{item.status === '1'
                                ? 'stock-color-red'
                                : item.status === '-1'
                                ? 'stock-color-green'
                                : ''
                            }}
                        " data-s-4f97313c>{{item.ratio}}</div>
                    <div class="
                            summary
                            s-line-clamp1
                            c-font-normal
                            c-color-gary2
                            c-gap-top-small
                        " data-s-4f97313c>{{item.summary}}</div>
                    <div class="icon-box {{item.selected ? 'selected' : ''}}" on-click="selectItem($event, rowIndex, index)" data-s-4f97313c>
                        <i class="s-icon icon" data-s-4f97313c></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-control" data-s-4f97313c>
            <s-button type="default" text="换一批" min-width="129" has-slot loading="{{reloadLoading}}" class="c-gap-right" on-click="reload" data-s-4f97313c>
                <i class="s-icon" style="font-size: 14px;" data-s-4f97313c></i>
            </s-button>
            <s-button type="primary" has-slot min-width="129" text="加自选({{selectedNum}}/{{showList.count}})" on-click="addStock" data-s-4f97313c>
                <i class="s-icon" style="font-size: 14px;" data-s-4f97313c></i>
            </s-button>
        </div>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 自选推荐股票
 */

import {Component, DataTypes} from 'san';
import {getSelfRecommentedStockList, addStock} from '@/api/index';
import Title from '@/components/title/title';
import {connect} from '@/lib/Store';
import MarketLabel from '@/components/market-label/market-label';
import Button from '@/components/button/button';

class SelfRecommentedStockList extends Component {
    static dataTypes = {
        width: DataTypes.number,
        list: DataTypes.array,
    }

    static components = {
        's-title': Title,
        's-market-label': MarketLabel,
        's-button': Button,
    }

    initData() {
        return {
            // 默认显示的是第 0 页
            pn: 1,
            selectedCount: 0,
            // 选中的股票列表
            addStcoks: [],
            // 136 是一个股票的宽度
            itemWidth: 136,
            // 16 是一只股票右侧最小外间距
            itemRightMargin: 16,
            reloadLoading: false,
        };
    }

    static computed = {
        showList() {
            const width = this.data.get('width');
            const list = this.data.get('list');
            const itemWidth = this.data.get('itemWidth');
            const itemRightMargin = this.data.get('itemRightMargin');
            let colNum = Math.floor(width / 136);
            const contentWidth = colNum * itemWidth + colNum * itemRightMargin - itemRightMargin;
            colNum = contentWidth > width ? colNum - 1 : colNum;

            const row1 = [...list.slice(0, colNum)];
            const row2 = [...list.slice(colNum, colNum * 2)];
            const newList = [row1, row2];
            return {
                list: newList,
                rowNum: (row1.length ? 1 : 0) + (row2.length ? 1 : 0),
                count: row1.length + row2.length,
            };
        },

        // 选中的股票数
        selectedNum() {
            const addStcoks = this.data.get('addStcoks');
            const showList = this.data.get('showList');
            return addStcoks.length > showList.count ? showList.count : addStcoks.length;
        },
    }

    inited() {
        // 将前两个设置为已选
        this.selectItem(null, 0, 0);
        this.selectItem(null, 1, 0);
    }

    reload() {
        this.data.set('reloadLoading', true);
        const {pn, curMarket, curType} = this.data.get();
        let relMarket = curMarket;
        if (curType !== 'futures') {
            relMarket = '';
        }
        getSelfRecommentedStockList(pn, relMarket, curType).then(res => {
            this.data.set('reloadLoading', false);
            this.data.set('addStcoks', []);
            const name = 'recommend_' + (curType ? curType : 'stock');
            const list = res[name] || [];
            if (list.length) {
                this.data.set('list', list);
            }
            if (pn === 4) {
                this.data.set('pn', 0);
            }
            else {
                this.data.set('pn', pn + 1);
            }
        }).catch(() => {
            this.data.set('reloadLoading', false);
        });
    }

    selectItem(e, row, col) {
        e && e.stopPropagation();
        const colNum = this.data.get('showList.list')[0].length;
        const index = row * colNum + col;
        const list = this.data.get('list');
        list[index] = {
            ...list[index],
            selected: !list[index].selected,
        };
        const addStcoks = list.map(item => {
            if (item.selected) {
                return {
                    code: item.code,
                    market: item.market,
                    type: item.type,
                };
            }
        }).filter(i => i !== undefined);
        this.data.set('addStcoks', addStcoks);
        this.data.set('list', [...list]);
    }

    addStock() {
        if (!this.data.get('loginInfo').isLogin) {
            this.$login();
            return;
        }
        const list = this.data.get('list');
        // 过滤需要添加自选的股票
        const stocks = list.map(item => {
            if (item.selected) {
                return {
                    code: item.code,
                    market: item.market,
                    type: item.type,
                };
            }
        }).filter(i => !!i);
        addStock(stocks).then(res => {
            const addStocks = list.map(item => {
                if (item.selected) {
                    return item;
                }
            }).filter(i => !!i);
            this.$store.dispatch('setSelfStockList', {market: this.data.get('curMarket'), list: addStocks});
        });
    }

    clickItem(data) {
        const {name, market, code, type} = data;
        this.$router.push({
            path: `/${type}/${market}-${code}`,
            query: {
                name: encodeURIComponent(name),
            },
        });
    }
}
export default connect.san({
    loginInfo: 'loginInfo',
})(SelfRecommentedStockList);
</script>

<style lang="less" scoped>
.recommented-stock-container[data-s-4f97313c] {
    .list-content {
        position: relative;
        overflow: hidden;

        .row-item {
            margin-top: .1rem;
            display: flex;
            flex-wrap: wrap;

            .stock-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 1.36rem;
                height: 1.44rem;
                box-sizing: border-box;
                flex-shrink: 0;
                border: 1px solid #f5f5f5;
                border-radius: .08rem;
                overflow: hidden;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 0 8px 0 rgba(242 244 247);
                }

                &.selected {
                    border: 1px solid #336bff;
                }

                .name {
                    max-width: 100%;
                    font-weight: 500;
                }

                .market-code {
                    display: flex;
                    align-items: center;
                }

                .summary {
                    max-width: 100%;
                }

                .icon-box {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: .24rem;
                    height: .16rem;
                    background: #e4e4e4;
                    border-top-left-radius: .08rem;
                    cursor: pointer;

                    &.selected {
                        background: #336bff;
                    }

                    .icon {
                        color: #fff;
                        line-height: .16rem;
                    }
                }
            }
        }
    }

    .bottom-control {
        display: flex;
        justify-content: center;
        padding: .32rem 0 .36rem 0;

        .bottom-inner {
            display: inline-flex;
            align-items: center;
        }
    }
}
</style>
