function computedHot(hot) {
    hot = +hot;
    if (hot >= 1000 * 10000) {
        hot = Math.round(hot / 10000) + 'w';
    }
    else if (hot > 10000) {
        hot = (hot / 10000).toFixed(1) + 'w';
    }
    return hot;
};
export default computedHot;