<template>
    <div class="match-module-container" id="match-module-container-{{name}}" data-s-0bd93592>
        <slot data-s-0bd93592></slot>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 局部模块容器组件 会监听容器宽度变化，暴露父组件
 */

import {Component, DataTypes} from 'san';
import elementResizeDetectorMaker from 'element-resize-detector';

export default class MatchModuleContainer extends Component {

    static dataTypes = {
        namespace: DataTypes.string.isRequired,
    }

    initData() {
        return {
            erd: null,
            name: '',
        };
    }

    inited() {
        this.data.set('name', `${this.data.get('namespace')}-${Math.random()}`);
        this.data.set('erd', elementResizeDetectorMaker());
    }

    attached() {
        const dom = document.getElementById(`match-module-container-${this.data.get('name')}`);
        if (dom) {
            this.data.get('erd').listenTo(dom, ele => {
                this.fire('resize', {
                    width: ele.offsetWidth,
                    height: ele.offsetHeight,
                    top: ele.offsetTop,
                    left: ele.offsetLeft,
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.match-module-container[data-s-0bd93592] {
    width: 100%;
    height: 100%;
}
</style>
