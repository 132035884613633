<template>
    <div class="capsule-tabs-container type-{{type}}" data-s-3835dc92>
        <div class="capsule-tabs {{arrayPosition === 'right' ? 'flex-right'
                : arrayPosition === 'center' ? 'flex-center' : 'flex-left' }}" data-s-3835dc92>
            <template s-if="arrType === 'arr'" data-s-3835dc92>
                <div s-for="tab, index in titleList" class="public-tab c-border-raidus-middle c-color-gary2 c-bg-color-light-gray
                        {{type ? type + '-tag' : ''}}
                        {{arrayPosition === 'right' ? 'c-gap-left' : 'c-gap-right'}}
                        {{index === activeIndex ? 'active-tag' : ''}}" on-click="handleChange(index, tab)" data-s-3835dc92>{{tab}}</div>
            </template>
            <template s-if="arrType === 'kvArr'" data-s-3835dc92>
                <div s-for="tab, index in titleList" class="public-tab c-border-raidus-middle c-color-gary2 c-bg-color-light-gray
                        {{type ? type + '-tag' : ''}}
                        {{arrayPosition === 'right' ? 'c-gap-left' : 'c-gap-right'}}
                        {{index === activeIndex ? 'active-tag' : ''}}" on-click="handleChange(index, tab)" data-s-3835dc92>{{tab.text}}</div>
            </template>
        </div>
    </div>
</template>

<script>
import {Component} from 'san';

export default class Welcome extends Component {
    static components = {};

    initData() {
        return {
            activeIndex: 0,
            arrType: 'arr',
        };
    }

    handleChange(index, tab) {
        const activeIndex = this.data.get('activeIndex');
        if (activeIndex === index) {
            return;
        }
        this.data.set('activeIndex', index);
        this.fire('event-click', {index, text: tab});
    }

    updateActiveIndex(index) {
        this.data.set('activeIndex', index);
    }
}
</script>

<style lang="less" scoped>
.capsule-tabs-container[data-s-3835dc92] {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 0.08rem;
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 25%, #fff 100%);
    }

    .capsule-tabs {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        overflow-x: auto;
        font-size: 0.14rem;
        line-height: 1;

        &::-webkit-scrollbar {
            display: none;
        }

        .flex-center {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .flex-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .flex-left {
            display: flex;
            align-items: center;
        }

        .public-tab {
            padding: 0.09rem 0.12rem;
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                background: rgba(65, 109, 249, 0.1);
                color: #416df9;
            }
        }

        .active-tag {
            background: rgba(65, 109, 249, 0.1);
            color: #416df9;
        }
    }
}

.type-small[data-s-3835dc92] {
    &::after {
        content: none;
    }
    .capsule-tabs {
        .public-tab {
            line-height: 24px;
            height: 24px;
            padding: 0 0.08rem;
            background: none;
            border-radius: 8px;
            &:hover {
                color: #416df9;
                background: none;
            }
        }
        .active-tag {
            background: rgba(65, 109, 249, 0.1);
            color: #416df9;
        }
    }
}
</style>
