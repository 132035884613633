/**
 * @function :  插件的调用。
 * @how2use  :  使用过程，可以复制过去，仅需修改调用按钮id即可；如需透传数据信息，请联系百度反馈接口人。
 */

// 页面加载脚本判定函数
function loadScript(url, callback, opt = {}) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    if (opt.charset) {
        script.charset = opt.charset;
    }
    if (opt.id) {
        script.id = opt.id;
    }

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        };
    }
    else {
        script.onload = function () {
            callback();
        };
    }
    script.src = url;
    document.body.appendChild(script);
}

export default (function () {
    /**
     * @function :第一次加载插件，之后调用插件
     */
    function init_feedback() {
        if (window.bds && window.bds.qa && window.bds.qa.ShortCut && window.bds.qa.ShortCut.initRightBar) {
            // 初始化反馈插件的样式参数

            const fb_options = {
                needImage: true, // 是否需要用户展示图片(截图，或者上传)
                upload_file: true, // 是否选择上传图片， true 表示上传图片，false表示截图
                appid: 262563, // 产品线的id
                productLine: 91223, //
                wenjuanTitle: '', // 问卷调查标题
                wenjuanURL: '', // 问卷调查链接
                issuePlaceholder: '请输入问题描述', // 问题描述提示语
                contactPlaceholder: '请输入邮件、手机号码或QQ号码任意一种联系方式', // 联系方式提示语
                showPosition: 'center', // 显示位置，中心：center，右下： bottom_right
                contactWayType: 'contact_way', // 联系方式类型 contact_way，email，qq，tel
                needContactWay: true, // 是否显示联系方式
                needHotQuestion: false, // 是否显示常见问题
                needQuestionnaire: false, // 是否显示问卷调查
                needFeedbackType: false, // 是否显示反馈类型
                needProductType: false, // 是否显示问题类型
                needEvaluate: true, // 是否启用用户评价
                typeArray: [], // 下拉列表项
                titleBgColor: '#F5F5F5', // 标题栏背景色
                buttonColor: '#0065C8', // 按钮的颜色
                mainFontColor: '#151515', // 文字的主体颜色
                secondaryFontColor: '#999999', // 文字的辅助颜色(用于input textarea输入框的提示文本)
                titleColor: '#333333', // 标题栏颜色
                hotQuestionArray: [], // 启用常见问题，列举常见的问题 <=5 个
                authType: 'ufo', // 环境
            };

            window.bds.qa.ShortCut.initRightBar(fb_options);
            // 产品线需要透传的参数，注意：json的标题必须是规范json写法，双引号，最后无逗号
            const pro_data = {
                // "extend_feedback_channel": 0  // 反馈来源
            };
            window.bds.qa.ShortCut._getProData(pro_data);
        }
    }

    /**
     * @function :校验js加载完成
     * @returns :{boolean}
     */
    window.loadRightBar = function loadRightBar() {
        if (window.bds && window.bds.qa && window.bds.qa.ShortCut) {
            init_feedback();
        }
        else {
            loadScript(
                'https://ufosdk.baidu.com/Public/feedback/js/dist/feedback_plugin_2.0.js',
                function () {
                    init_feedback();
                }, {
                    charset: 'utf-8',
                    id: 'feedback_script',
                });
        }
        return false;
    };
})();
