<template>
    <div class="stock-marking" data-s-d75581e0>
        <div class="name c-color" style="width: {{nameWidth ? nameWidth : 1.2}}rem;" data-s-d75581e0>{{name}}</div>
        <div s-if="code" class="marking c-color-gray" data-s-d75581e0>
            <s-market-label market="{{type}}" s-if="{{type}}" data-s-d75581e0></s-market-label>
            <span class="code {{type ? 'c-gap-left-lh' : ''}}" data-s-d75581e0>{{code}}</span>
            <span s-if="{{belongingBlock}}" class="small-line" data-s-d75581e0>|</span>
            <span s-if="{{belongingBlock}}" class="small-text" data-s-d75581e0>{{belongingBlock}}</span>
            <span s-if="{{useDelayed}}" class="code-text c-gap-left-lh" data-s-d75581e0>延时</span>
        </div>
    </div>
</template>

<script>
import {Component} from 'san';
import MarketLabel from '@/components/market-label/market-label';
export default class Welcome extends Component {
    static components = {
        's-market-label': MarketLabel,
    }
}
</script>

<style lang="less" scoped>
@import '~santd/es/core/styles/themes/default.less';

.stock-marking[data-s-d75581e0] {
    font-size: .14rem;
    line-height: 1;
    font-weight: 500;

    .name {
        width: 1.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: .08rem;
    }

    .marking {
        line-height: 1;
        display: flex;
    }

    .code {
        font-weight: 400;
        font-size: .12rem;
    }

    .small-line {
        margin: -.01rem -.02rem 0 .03rem;
        font-size: .12rem;
        transform: scale(.8);
        font-weight: normal;
        color: #e0e0e0;
    }

    .small-text {
        height: .15rem;
        display: flex;
        align-items: center;
        padding: 0 .02rem;
        background: #f5f5f5;
        border-radius: .02rem;
        font-size: .1rem;
        transform: scale(.8);
        font-weight: 400;
        white-space: nowrap;
    }

    .code-text {
        background: #f5f5f5;
        border-radius: .03rem;
        font-size: .1rem;
        color: #999;
        font-weight: 400;
        transform: scale(.93);
    }
}

</style>