<template>
    <div class="main-header-container" on-click="sideNavClose" data-s-051afd96>
        <div class="main-nav-content" data-s-051afd96>
            <div class="main-nav-left" data-s-051afd96>
                <i class="s-icon side-nav-bar-btn" on-click="sideNavShow" data-s-051afd96></i>
                <router-link class="main-nav-logo" to="/" data-s-051afd96></router-link>
            </div>
            <s-match-module-container namespace="main-header-container-module" on-resize="resize" data-s-051afd96>
                <div class="main-nav-right" data-s-051afd96>
                    <s-finance-search container-width="{{width}}" data-s-051afd96></s-finance-search>
                    <s-download-app s-if="showXiaogu || showGushitong" showXiaogu="{{showXiaogu}}" showGushitong="{{showGushitong}}" data-s-051afd96></s-download-app>
                    <s-user data-s-051afd96></s-user>
                </div>
            </s-match-module-container>
        </div>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 头部导航栏
 */

import {Component} from 'san';
import FinanceSearch from '@/components/finance-search/finance-search';
import DownloadApp from '@/components/download-app/download-app';
import User from '@/components/user/user';
import {Link} from 'san-router';
import MatchModuleContainer from '@/components/match-module-container/match-module-container';
import {connect} from '@/lib/Store';

class MainHeaderNav extends Component {
    static components = {
        's-finance-search': FinanceSearch,
        's-user': User,
        'router-link': Link,
        's-match-module-container': MatchModuleContainer,
        's-download-app': DownloadApp,
    }

    initData() {
        return {
            showXiaogu: false,
            showGushitong: false,
        };
    }

    attached() {

        // 首页显示2个，基金详情页显示小顾基金，其他显示股市通APP
        if (this.data.get('curPage') === 'fund') {
            this.data.set('showXiaogu', true);
        }
        else {
            this.data.set('showXiaogu', false);
        }
        this.watch('curPage', value => {
            if (value === 'fund') {
                this.data.set('showXiaogu', true);
            }
            else {
                this.data.set('showXiaogu', false);
            }
        });
    }

    resize({width}) {
        this.data.set('width', width);
    }

    sideNavShow(e) {
        e.stopPropagation();
        this.$store.dispatch('changeSideNav', true);
        this.fire('event-reset-x');
    }

    sideNavClose(e) {
        e.stopPropagation();
        this.$store.dispatch('changeSideNav', false);
    }
}

export default connect.san({
    curPage: 'curPage',
})(MainHeaderNav);
</script>

<style lang="less" scoped>
.main-header-container[data-s-051afd96] {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100vw;
    height: .72rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 .02rem .12rem 0 rgba(0, 0, 0, .08);

    .main-nav-content {
        margin: 0 auto;
        width: 12.48rem;
        padding: 0 .24rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .main-nav-left {
            flex-shrink: 0;
            width: 2.44rem;
            height: .44rem;

            .side-nav-bar-btn {
                font-size: .2rem;
                height: .44rem;
                line-height: .44rem;
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
            }

            .main-nav-logo {
                width: 2.06rem;
                height: .44rem;
                background: url('https://ss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=297903074,1394687964&fm=179&app=35&f=PNG?w=412&h=88&s=6C85CA1A4D4A3F7C4E59D10F030070C0');
                background-size: 100% 100%;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .main-nav-right {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: .24rem;
        }
    }
}
</style>
