<template>
    <div class="
        footer-container
        c-gap-top-large
        c-font-small
    " data-s-4b52de9c>
        <div class="
            main-container
            footer-content
            c-gap-inner-left-large
            c-gap-inner-right-large
        " data-s-4b52de9c>
            <div class="box" data-s-4b52de9c>
                <span class="c-gap-right c-color-source" data-s-4b52de9c>©{{year}}Baidu</span>
                <a href="https://www.baidu.com/duty/" target="view_window" class="item c-gap-right c-color-source" data-s-4b52de9c>使用百度前必读</a>
                <span id="footer-feedback-btn" class="item c-gap-right c-color-source" data-s-4b52de9c>投诉建议</span>
                <span id="footer-disclaimers-btn" on-click="disclaimerAlert" class="item c-gap-right c-color-source" data-s-4b52de9c>免责声明</span>
            </div>
            <div class="box" data-s-4b52de9c>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000001" target="view_window" class="item c-gap-right c-color-source" data-s-4b52de9c>京公网安备11000002000001号</a>
                <a href="http://news-bos.cdn.bcebos.com/mvideo/pcnews_licence.html" target="view_window" class="item c-gap-right c-color-source" data-s-4b52de9c>互联网新闻信息服务许可</a>
                <a href="https://www.piyao.org.cn/yybgt/index.htm" target="view_window" class="item c-gap-right c-color-source" data-s-4b52de9c>网络谣言曝光台</a>
                <a href="https://12377.cn" target="view_window" class="item c-color-source" data-s-4b52de9c>网上有害信息举报</a>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc 页脚
 */

import {Component} from 'san';
import '@/assets/js/feedback';

export default class MainFooter extends Component {
    initData() {
        return {
            year: new Date().getFullYear(),
        };
    }

    attached() {
        const feedbackBtn = document.getElementById('footer-feedback-btn');
        if (feedbackBtn) {
            feedbackBtn.onclick = window.loadRightBar;
        }
    }
    disclaimerAlert() {
        this.fire('disclaimers-click');
    }
}
</script>

<style lang="less" scoped>
.footer-container[data-s-4b52de9c] {
    background-color: #f3f3f3;
    height: 1.02rem;
    display: flex;
    align-items: center;

    .footer-content {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .box {
            display: flex;
            flex-wrap: nowrap;
            line-height: .2rem;
        }
    }

    .item {
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            color: #416df9;
        }
    }
}
</style>
