<template>
    <div data-s-a0d973d2>
        <div id="main" on-scroll="move" data-s-a0d973d2></div>
    </div>
</template>

<script>
import {Component} from 'san';

export default class Simple extends Component {};
</script>

<style lang="less" scoped>

#main[data-s-a0d973d2] {
    min-height: calc(100vh - 72px - 126px);
}
</style>