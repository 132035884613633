<template>
    <div class="main-side-nav-container" id="sideNav" style="left: {{navLeft}}px;" data-s-38e948d6>
        <div class="logo-content" on-click="close" data-s-38e948d6>
            <router-link class="logo" to="/" data-s-38e948d6></router-link>
        </div>
        <div class="nav-content" data-s-38e948d6>
            <s-match-module-container namespace="home-left-module" on-resize="leftResizeChange" data-s-38e948d6>
                <div class="btn-list" data-s-38e948d6>
                    <div class="btn-list-item" on-click="goHome" data-s-38e948d6>
                        <i class="s-icon icon-home {{curPage === 'home' ? 'active' : ''}}" data-s-38e948d6></i>
                        <span class="{{curPage === 'home' ? 'active' : ''}}" data-s-38e948d6>首页</span>
                    </div>
                    <div class="btn-list-item" on-click="goCalendar" data-s-38e948d6>
                        <i class="s-icon icon-calendar {{curPage === 'calendar' ? 'active' : ''}}" data-s-38e948d6></i>
                        <span class="{{curPage === 'calendar' ? 'active' : ''}}" data-s-38e948d6>财经日历</span>
                    </div>
                    <div class="btn-list-item" on-click="goAccount" data-s-38e948d6>
                        <i class="s-icon icon-calendar {{curPage === 'openAccount' ? 'active' : ''}}" data-s-38e948d6></i>
                        <span class="{{curPage === 'openAccount' ? 'active' : ''}}" data-s-38e948d6>股票开户</span>
                    </div>
                </div>
                <div s-if="dataIsReady" data-s-38e948d6>
                    <!-- 此处登陆显示自选列表，未登陆显示自选股票  -->
                    <s-self-stock-list s-if="showSelfStockList" isNav="{{true}}" width="{{240}}" initList="{{allSelfStockData}}" data-s-38e948d6></s-self-stock-list>
                    <hot-stocks-list s-else inSideNav="{{true}}" data-s-38e948d6></hot-stocks-list>
                </div>
            </s-match-module-container>
        </div>
    </div>
</template>

<script>
/**
 * @author heyingdi01
 * @desc 侧边导航栏
 */

import {Component} from 'san';
import {connect} from '@/lib/Store';
import {Link} from 'san-router';
import HotStocksList from '@/components/hot-stocks-list/hot-stocks-list';
import SelfStockList from '@/components/self-stock-list/self-stock-list';

class MainHeaderNav extends Component {
    static components = {
        'router-link': Link,
        'hot-stocks-list': HotStocksList,
        's-self-stock-list': SelfStockList,
    }

    initData() {
        return {
            lists: [1, 2, 3],
            showSelf: true,
        };
    }

    static computed = {
        // 是否显示自选股票
        showSelfStockList() {
            let data = this.data.get('allSelfStockData');
            return data && data[0]?.market;
        },
    }

    inited() {
        this.$store.dispatch('getAllSelfStockData', {});
    }

    goHome() {
        this.$store.dispatch('changeSideNav', false);
        this.$router.push('/');
    }

    goCalendar() {
        this.$store.dispatch('changeSideNav', false);
        this.$router.push('/calendar');
    }

    goAccount() {
        this.$store.dispatch('changeSideNav', false);
        this.$router.push('/open-account/open-account');
    }

    // 热股item点击，进入详情页
    onHotStockItem(itemInfo) {
        const {code, name, market} = itemInfo;
        this.$router.push({
            path: `/stock/${market}-${code}`,
            query: {
                name: encodeURIComponent(name),
            },
        });
    }

    close() {
        this.$store.dispatch('changeSideNav', false);
    }
}

export default connect.san({
    curPage: 'curPage',
    navLeft: 'sideNavLeft',
    selfStockList: 'selfStockList',
    allSelfStockData: 'allSelfStockData',
    dataIsReady: 'dataIsReady',
})(MainHeaderNav);
</script>

<style lang="less" scoped>
.main-side-nav-container[data-s-38e948d6] {
    position: fixed;
    z-index: 1001;
    top: 0;
    width: 3.4rem;
    height: 100vh;
    background-color: #fff;
    box-shadow: .02rem 0 .12rem 0 rgba(0, 0, 0, .08);
    overflow-y: auto;
    overflow-x: hidden;
    transition: left .3s;

    .logo-content {
        width: 3.4rem;
        height: .72rem;
        background: #fff;

        .logo {
            width: 2.06rem;
            height: .44rem;
            margin: .14rem 0 0 .24rem;
            background: url('https://ss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=297903074,1394687964&fm=179&app=35&f=PNG?w=412&h=88&s=6C85CA1A4D4A3F7C4E59D10F030070C0');
            background-size: 100% 100%;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .nav-content {
        .btn-list {
            margin: .24rem 0 0 0;
            padding-bottom: .155rem;
            border-bottom: 1px solid #f5f5f5;

            .btn-list-item {
                font-size: .14rem;
                color: #1f1f1f;
                font-weight: 500;
                height: .48rem;
                line-height: .48rem;
                width: 100%;
                padding-left: .25rem;

                i {
                    color: #1f1f1f;
                    font-size: .14rem;
                    margin-right: .04rem;
                }

                &:hover {
                    background-color: #f8f9fe;
                    cursor: pointer;
                    color: #416df9;

                    i {
                        color: #416df9;
                    }
                }

                .active {
                    color: #416df9;
                }
            }
        }
    }
}
</style>
