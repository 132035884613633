<template>
    <div class="button-container {{height}} c-gap-inner-left-middle c-gap-inner-right-middle {{type}}" style="min-width: {{minWidth / 100}}rem;" on-click="clickBtn($event)" data-s-2c441052>
        <div class="{{loading ? 'loading' : ''}}" data-s-2c441052>
            <slot data-s-2c441052></slot>
        </div>
        <div class="text c-font-normal {{hasSlot ? 'has-slot' : ''}}" data-s-2c441052>{{text}}</div>
    </div>
</template>

<script>
/**
 * @author v_zhaoxinggang
 * @desc button组件
 */

import {Component, DataTypes} from 'san';


export default class Button extends Component {
    static components = {
        's-button': Button,
    }

    static dataTypes = {
        // 按钮最小宽度, 默认自适应，如果传了宽度按宽度适配, 单位px
        minWidth: DataTypes.string,
        // small 32px 高度 middle 40px高度
        height: DataTypes.oneOf(['small', 'middle']),
        type: DataTypes.oneOf(['primary', 'default']),
        hasSlot: DataTypes.bool,
        loading: DataTypes.bool,
        text: DataTypes.string,
    }

    initData() {
        return {
            height: 'middle',
            type: 'default',
            minWidth: 'auto',
            hasSlot: false,
            loading: false,
        };
    }

    clickBtn(e) {
        e.stopPropagation();
        this.fire('click');
    }
}
</script>

<style lang="less" scoped>
@keyframes loading-data-s-2c441052 {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

.button-container[data-s-2c441052] {
    display: inline-flex;
    border-radius: .09rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;

    &.middle {
        height: .4rem;
    }

    &.small {
        height: .32rem;
    }

    &.primary {
        background-color: #416df9;
        color: #fff;
        position: relative;

        &:hover::after {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(255, 255, 255, .16);
            border-radius: .09rem;
        }
    }

    &.default {
        background-color: #f5f5f5;
        color: #1f1f1f;

        &:hover {
            background-color: rgba(0, 0, 0, .06);
        }
    }

    .loading {
        animation: loading-data-s-2c441052 1s infinite;
        transform-origin: center;
    }

    .has-slot {
        margin-left: .05rem;
    }
}
</style>
