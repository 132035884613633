<template>
    <div class="container level-{{level}}" data-s-55e622c4>
        <div class="left-info" data-s-55e622c4>
            <div class="title c-color c-font-title0{{level}}" data-s-55e622c4>{{title}}</div>
            <i s-if="tip" class="tip s-icon c-gap-left-small c-color" data-s-55e622c4>
                
                <span class="
                        tip-text
                        c-font-small
                        c-gap-inner-left-small
                        c-gap-inner-right-small
                    " data-s-55e622c4>{{tip}}</span>
            </i>
            <span class="slot-tips-contianer" data-s-55e622c4>
                <slot name="tip" data-s-55e622c4></slot>
            </span>
            <span s-if="subText" class="c-font-normal c-gap-left-middle c-color-gray" data-s-55e622c4>{{subText}}</span>
        </div>

        <div s-if="{{arrowText || arrow}}" class="arrow-box" on-click="click" data-s-55e622c4>
            <span s-if="arrowText" class="c-font-normal c-color-gray text" data-s-55e622c4>{{arrowText}}</span>
            <i s-if="arrow" class="arrow s-icon" data-s-55e622c4></i>
        </div>

        <div s-if="{{rightText}}" class="right-box" data-s-55e622c4>
            <span class="c-font-normal right-text" data-s-55e622c4>{{rightText}}</span>
        </div>
    </div>
</template>

<script>
import {Component, DataTypes} from 'san';

export default class Title extends Component {
    static dataTypes = {
        title: DataTypes.string,
        tip: DataTypes.string,
        subText: DataTypes.string,
        arrow: DataTypes.bool,
        level: DataTypes.string,
        arrowText: DataTypes.string,
        rightText: DataTypes.string,
    }

    initData() {
        return {
            arrow: true,
            level: '1',
        };
    }

    click() {
        this.fire('click');
    }
}
</script>

<style lang="less" scoped>
.container[data-s-55e622c4] {
    height: .48rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .left-info {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .title {
            font-weight: 500;
            white-space: nowrap;
        }

        .tip {
            position: relative;
            line-height: .14rem;
            font-size: .16rem;
            color: #b7b9c1;

            .tip-text {
                display: none;
                position: absolute;
                top: -.09rem;
                left: .29rem;
                opacity: .9;
                background: #525252;
                border-radius: .04rem;
                color: #fff;
                padding-top: .1rem;
                padding-bottom: .1rem;
                white-space: nowrap;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: -.03rem;
                    top: 50%;
                    left: -.03rem;
                    transform: translateY(-50%) rotateZ(45deg);
                    width: 0;
                    height: 0;
                    border: .035rem solid #525252;
                    border-top: .035rem solid transparent;
                    border-right: .035rem solid transparent;
                    border-bottom-left-radius: .01rem;
                }
            }

            &:hover {
                color: #416df9;

                .tip-text {
                    display: block;
                    color: #fff;
                }
            }
        }
    }

    .arrow-box {
        display: flex;
        align-items: center;
        cursor: pointer;

        .arrow {
            font-size: .2rem;
        }

        &:hover {
            .arrow,
            .text {
                color: #416df9 !important;
            }
        }
    }

    .right-box {
        display: flex;
        align-items: center;

        .right-text {
            color: #848691;
        }
    }

    &.level-2 {
        height: .4rem;
        font-size: .18rem;
    }

    &.level-3 {
        height: .34rem;
        font-size: .18rem;
    }

    .slot-tips-contianer {
        display: flex;
        align-items: center;
    }
}
</style>
