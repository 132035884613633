
function safeCheck(str) {
    // 匹配script  style
    const reg = /((<script[\s\S]*?<\/script>)|(<style[\s\S]*?<\/style>))/i;

    // script 和 style 匹配到就会encode之后输出
    str = str.replace(reg, res => {
        return encodeURI(res);
    });

    // 匹配s-html中的onXXX => onXXX-safe
    const arr = str.split(/(?=<)/);
    const len = arr.length;
    let resultStr = '';
    for (let i = 0; i < len; i++) {
        if (/^<\//g.test(arr[i]) || !/[<>]/g.test(arr[i])) {
            resultStr += arr[i];
        }
        else {
            const reg_on = /(\s*)(on[^\s\"'<>\/=]+)(\s*=)/i;

            const index = arr[i].indexOf('>');
            let front = arr[i].substr(0, index);
            const back = arr[i].substr(index);

            front.replace(reg_on, (s1, s2, s3) => {
                return s1.replace(s3, s3 + '-safe');
            });

            resultStr += front + back;
        }
    }

    return resultStr;
}
export default safeCheck;



